import { Injectable } from '@angular/core';
import { UserDetailsResponse } from '../api/models/user-details-response';
import { take } from 'rxjs/operators';
import { MyService } from '../api/services/my.service';
import { Subject } from 'rxjs';
import { TranslationService } from '../translations/translation.service';
import { UtilsService } from '../utils/utils.service';
import { PermissionService } from './permission-service';
import { LanguageEnum } from '../api/models/language-enum';

export const USER_RES_KEY = 'userRes';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  public valuesInitialized: Subject<void> = new Subject<void>();

  constructor(
    private myService: MyService,
    private translationService: TranslationService,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {}

  public get userRes(): UserDetailsResponse {
    return JSON.parse(
      localStorage.getItem(USER_RES_KEY)
    ) as UserDetailsResponse;
  }

  public setUserDetails(info?: UserDetailsResponse) {
    if (info) {
      localStorage.setItem(USER_RES_KEY, JSON.stringify(info));
      this.setLanguage(info.language);
    } else {
      this.myService
        .getMyUserDetails()
        .pipe(take(1))
        .subscribe((res) => {
          localStorage.setItem(USER_RES_KEY, JSON.stringify(res));
          this.setLanguage(res.language);
          this.permissionService.getPermissions();
          this.valuesInitialized.next();
        });
    }
  }

  private async setLanguage(lang: LanguageEnum) {
    await this.translationService.selectLanguage(lang, true);
  }
}
