/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApplicationSettingsService } from './services/application-settings.service';
import { AvatarService } from './services/avatar.service';
import { CropReportService } from './services/crop-report.service';
import { CustomerService } from './services/customer.service';
import { CustomerFeedMillService } from './services/customer-feed-mill.service';
import { CustomerHatcheryService } from './services/customer-hatchery.service';
import { CustomerQuickInsightsThresholdSettingService } from './services/customer-quick-insights-threshold-setting.service';
import { FacilityService } from './services/facility.service';
import { FacilityGroupNotificationSettingService } from './services/facility-group-notification-setting.service';
import { FacilityTypeService } from './services/facility-type.service';
import { FacilityGroupService } from './services/facility-group.service';
import { FacilityStatusReportService } from './services/facility-status-report.service';
import { FacilityZoneTypeService } from './services/facility-zone-type.service';
import { KpiFarmSettingsService } from './services/kpi-farm-settings.service';
import { HarvestEventsLookupService } from './services/harvest-events-lookup.service';
import { HarvestService } from './services/harvest.service';
import { HatcheryService } from './services/hatchery.service';
import { IoTEventsService } from './services/io-t-events.service';
import { KpiService } from './services/kpi.service';
import { BaseService } from './services/base.service';
import { LookupTableService } from './services/lookup-table.service';
import { LoadTestingService } from './services/load-testing.service';
import { HarvestMetricTargetService } from './services/harvest-metric-target.service';
import { MyService } from './services/my.service';
import { NmsService } from './services/nms.service';
import { NotificationService } from './services/notification.service';
import { AlertNotificationSettingService } from './services/alert-notification-setting.service';
import { RegisteredDeviceService } from './services/registered-device.service';
import { RoleService } from './services/role.service';
import { SystemNoteService } from './services/system-note.service';
import { TelemetryService } from './services/telemetry.service';
import { UserService } from './services/user.service';
import { UserAlertNotificationSettingService } from './services/user-alert-notification-setting.service';
import { UserActivityLogService } from './services/user-activity-log.service';
import { VaccinationService } from './services/vaccination.service';
import { VersionResourceService } from './services/version-resource.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApplicationSettingsService,
    AvatarService,
    CropReportService,
    CustomerService,
    CustomerFeedMillService,
    CustomerHatcheryService,
    CustomerQuickInsightsThresholdSettingService,
    FacilityService,
    FacilityGroupNotificationSettingService,
    FacilityTypeService,
    FacilityGroupService,
    FacilityStatusReportService,
    FacilityZoneTypeService,
    KpiFarmSettingsService,
    HarvestEventsLookupService,
    HarvestService,
    HatcheryService,
    IoTEventsService,
    KpiService,
    BaseService,
    LookupTableService,
    LoadTestingService,
    HarvestMetricTargetService,
    MyService,
    NmsService,
    NotificationService,
    AlertNotificationSettingService,
    RegisteredDeviceService,
    RoleService,
    SystemNoteService,
    TelemetryService,
    UserService,
    UserAlertNotificationSettingService,
    UserActivityLogService,
    VaccinationService,
    VersionResourceService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
