import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { LanguageComponent } from './language.component';

@NgModule({
  declarations: [LanguageComponent],
  imports: [CommonModule, ReactiveFormsModule, PipesModule, FormsModule],
  exports: [LanguageComponent],
})
export class LanguageModule {}
