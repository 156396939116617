<!-- DEFAULT MODE -->
<div class="display-flex position-relative margin-bottom-between-events"
     *ngIf="!event.values[idByParameterName('isRecurring')]?.value || event.values[idByParameterName('isRecurring')]?.value === 'false'">
  <div class="menus__menu">
    <div class="menus__menu-selector" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        [min]="startDate"
        [max]="maxDate"
        [value]="selectedDate"
        (valueChange)="changeSelectedDate($event)"
      ></date-picker>
    </div>
    <div class="menus__menu-selector height66"
         *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
    </div>
  </div>
  <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66"
         *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
    <div class="menus__menu-selector height66"
         *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
    </div>
  </div>
  <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
  <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
    <div class="menus__menu-selector custom-select custom-select__disabled">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
      <input disabled [value]="birdAge">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Comment' | translate }}</div>
      <input [value]="event.values[idByParameterName('Comment')].value" maxlength="100" (change)="commentChanged($event.target.value)">
      <small class="characters-left">{{ "CROP_CARD.VALIDATION.up_to_characters_left"| translate: null : '100' }}</small>
    </div>
  </div>
  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
  <div class="toggle-recurrent"
       *ngIf="functionalMode === EventFunctionalModes.addEvent && (rowIndex === harvestEvents.length-1)"
       (click)="onToggleRecurrent()">
    <div class="svg refresh-outlined mr-10"></div>
    {{ 'CROP_CARD.recurrent' | translate }}
  </div>
</div>

<!-- RECURRENT MODE -->

<div class="position-relative margin-bottom-between-events" *ngIf="event.values[idByParameterName('isRecurring')]?.value === 'true'">
  <div class="display-flex">
    <!--                              START DATE                         -->
    <div class="menus__menu">
      <div class="menus__menu-selector" *ngIf="isDateChanged">
        <div class="menus__menu-selector-label">{{ 'COMMON.Start_Date' | translate }}</div>
        <date-picker
          [min]="startDate"
          [max]="getMaxStartDate()"
          [value]="selectedDate"
          (valueChange)="changeSelectedDate($event)"
        ></date-picker>
      </div>
      <div class="menus__menu-selector height66"
           *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'COMMON.Start_Date' | translate }}</div>
        <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
      </div>
    </div>
    <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector height66"
           *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
        <input
          type="number"
          [(ngModel)]="birdAge"
          (change)="changeBirdAge($event.target.value)"
        >
      </div>
      <div class="menus__menu-selector height66"
           *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
        <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
      </div>
    </div>
    <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
    <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
      <div class="menus__menu-selector custom-select custom-select__disabled">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
        <input disabled [value]="birdAge">
      </div>
    </div>
    <!--                              END DATE                         -->
    <div class="menus__menu">
      <div class="menus__menu-selector" *ngIf="isRecurrentEndDateChanged">
        <div class="menus__menu-selector-label">{{ 'COMMON.End_Date' | translate }}</div>
        <date-picker
          [min]="getMinEndDate()"
          [max]="maxDate"
          [value]="recurrentEndDate"
          (valueChange)="changeRecurrentEndDate($event)"
        ></date-picker>
        <small class="out-of-range"
               *ngIf="isDateOutOfCropRange()">{{ "CROP_CARD.out_of_crop_range"| translate }}</small>
      </div>
      <div class="menus__menu-selector height66"
           *ngIf="!isRecurrentEndDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
        <input type="text" value="NA"
               (click)="isRecurrentEndDateChanged = true; changeRecurrentEndDate(recurrentEndDate)"/>
      </div>
    </div>
    <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector height66"
           *ngIf="!isRecurrentEndDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
        <input
          [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
          autonumeric
          [(ngModel)]="recurrentEndBirdAge"
          (change)="changeRecurrentEndBirdAge($event.target.value)"
        >
      </div>
      <div class="menus__menu-selector height66"
           *ngIf="isRecurrentEndDateChanged && functionalMode === EventFunctionalModes.addEvent">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
        <input type="text" value="NA"
               (click)="isRecurrentEndDateChanged = false; changeRecurrentEndBirdAge(recurrentEndBirdAge)"/>
      </div>
    </div>
    <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
    <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
      <div class="menus__menu-selector custom-select custom-select__disabled">
        <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
        <input disabled [value]="recurrentEndBirdAge">
      </div>
    </div>
  </div>
  <div class="display-flex">
    <div class="menus__menu">
      <div class="menus__menu-selector">
        <div class="menus__menu-selector-label">{{ 'EVENT.intervalDays' | translate }}</div>
        <input
         type="number"
          [(ngModel)]="event.values[idByParameterName('intervalDays')].value"
        >
      </div>
    </div>
    <div class="menus__menu">
      <div class="menus__menu-selector">
      </div>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Comment' | translate }}</div>
      <input [value]="event.values[idByParameterName('Comment')].value" maxlength="100" (change)="commentChanged($event.target.value)">
      <small class="characters-left">{{ "CROP_CARD.VALIDATION.up_to_characters_left"| translate: null : '100' }}</small>
    </div>
  </div>
  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
  <div class="toggle-recurrent"
       *ngIf="functionalMode === EventFunctionalModes.addEvent && (rowIndex === harvestEvents.length-1)"
       (click)="onToggleRecurrent()">
    <div class="svg refresh-outlined mr-10"></div>
    {{ 'CROP_CARD.recurrent' | translate }}
  </div>
</div>
