import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ErrorsService } from '../../../../services/errors-service/errors.service';

export interface IErrorContent {
  errorName: string;
  formattedMessage: string;
  message: string;
  params: string[];
}

@Component({
  selector: 'error-modal',
  templateUrl: 'error-modal.component.html',
  styleUrls: ['error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();
  public errorContentList: IErrorContent[] = [];

  constructor(private errorsService: ErrorsService) {}

  ngOnInit() {
    this.errorContentList = this.errorsService.errorContentList;
    console.log('errorContentList', this.errorContentList);
  }

  public closeModal() {
    this.onClose.emit();
  }
}
