import {NgModule} from '@angular/core';
import {HeaderComponent} from './header.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {PsSensorsModule} from '../dropdowns/ps-sensors/ps-sensors.module';
import {MyFarmModule} from '../dropdowns/my-farm/my-farm.module';
import {CustMgmtModule} from '../dropdowns/cust-mgmt/cust-mgmt.module';
import {AnalysisModule} from '../dropdowns/analysis/analysis.module';
import {ProfileModule} from '../dropdowns/profile/profile.module';
import {ProfileModalModule} from '../profile-modal/profile-modal.module';
import {PipesModule} from '../../pipes/pipes.module';
import {DropdownsModule} from '../dropdowns/dropdowns.module';
import {BreadcrumbsModule} from '../breadcrumbs/breadcrumbs.module';
import {NotificationsModule} from '../notifications/notifications.module';
import {InfoModule} from '../dropdowns/info/info.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    RouterModule,
    CommonModule,
    PsSensorsModule,
    MyFarmModule,
    CustMgmtModule,
    AnalysisModule,
    ProfileModule,
    ProfileModalModule,
    PipesModule,
    DropdownsModule,
    BreadcrumbsModule,
    NotificationsModule,
    InfoModule
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
