import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, EMPTY, Subject, throwError} from 'rxjs';
import { IProfile } from '../profile-modal.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { MyService } from '../../../../services/api/services/my.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LanguageEnum } from 'src/app/services/api/models/language-enum';
import {UserDetailsService} from '../../../../services/user/user-details.service';

@Component({
  selector: 'language',
  templateUrl: 'language.component.html',
  styleUrls: ['language.component.scss'],
})
export class LanguageComponent implements OnDestroy, OnInit {
  @Input() public profileData: IProfile;

  public isChangedLanguage: boolean = false;

  public LanguageEnum = LanguageEnum;

  public selectedLanguage: LanguageEnum = LanguageEnum.EnUs;

  private destroyed$ = new Subject();

  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(public myService: MyService, private router: Router, public userDetailsService: UserDetailsService) {}

  public ngOnInit() {
    this.selectedLanguage = this.profileData.language;
  }

  public updateMyUserDetails() {
    this.myService
      .updateMyUserDetails({
        body: {
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          phone: this.profileData.phone,
          language: this.selectedLanguage
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showSaveError.bind(this))
      )
      .subscribe((res) => {
        this.userDetailsService.setUserDetails();
        this.isChangedLanguage = false;
      });
  }

  public showSaveError(error: HttpErrorResponse) {
    this.showSaveFail$.next(true);
    return throwError(error);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
