<div class="popup" >
  <div class="popup__body">
    <div class="popup__body-header">
      {{ 'COMMON.ERRORS.Warning' | translate }}
    </div>
    <div class="error-content" *ngFor="let errorContent of errorContentList">
      <span class="svg warning-orange"></span>
      <span
        *ngIf="errorContent?.errorName !== 'ConstraintViolationException'"
        class="error-text">{{
          'COMMON.ERRORS.' + errorContent.errorName | translate : null :
            errorContent.params[0] :
            errorContent.params[1] :
            errorContent.params[2] :
            errorContent.params[3] :
            errorContent.params[4] :
            errorContent.params[5] :
            errorContent.params[6] :
            errorContent.params[7] :
            errorContent.params[8] :
            errorContent.params[9]
        }}</span>
      <span
        *ngIf="errorContent?.errorName === 'ConstraintViolationException'"
        class="error-text">{{ errorContent.formattedMessage }}</span>
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="closeModal()"></span>
    </div>
    <div class="center width-100">
      <button class="blue-rounded-button new-farm-button"
              (click)="closeModal()">{{ 'COMMON.BUTTONS.ok' | translate }}
      </button>
    </div>
  </div>
</div>
