import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../auth.service';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { ErrorsService } from '../../errors-service/errors.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private readonly configService: ConfigService,
    private errorsService: ErrorsService
  ) {}

  // tslint:disable-next-line:no-any
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.internalIntercept(request, next));
  }

  // tslint:disable-next-line:no-any
  private async internalIntercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    if (
      this.configService.configuration &&
      request.url.startsWith(this.configService.configuration.apiUrl)
    ) {
      let accessToken = await this.authService.getAccessToken();
      if (accessToken !== undefined) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + accessToken,
          },
        });
      } else {
        // for requests that should be requested without bearer
        request = request.clone({
          setHeaders: {},
        });
      }
    }
    return next
      .handle(request)
      .pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.authService.logout();
                this.errorsService.isShowError$.next(true);
              } else {
                console.log('err', err);
                const parsedError =
                  typeof err?.error === 'string'
                    ? JSON.parse(err?.error)
                    : err?.error;
                if (
                  parsedError.length > 0 &&
                  parsedError[0]?.hasOwnProperty('errorName') &&
                  parsedError[0]?.hasOwnProperty('formattedMessage') &&
                  parsedError[0]?.hasOwnProperty('message') &&
                  parsedError[0]?.hasOwnProperty('params')
                ) {
                  this.errorsService.errorContentList = parsedError;
                } else {
                  this.errorsService.errorContentList = [
                    {
                      errorName: 'OPERATION_FAILED',
                      formattedMessage:
                        'Operation failed. Please try again. In case this is an on going issue please contact support',
                      message:
                        'Operation failed. Please try again. In case this is an on going issue please contact support',
                      params: [],
                    },
                  ];
                }
                this.errorsService.isShowError$.next(true);
              }
              // else if (err.status === 409) {
              //   this.errorsService.isShowError$.next(true);
              // }
              return;
            }
          }
        )
      )
      .toPromise();
  }

  // // tslint:disable-next-line:no-any
  // public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   if (this.authService.getLoginStatus()) {
  //     request = request.clone({
  //       setHeaders: {
  //         Authorization: `${this.authService.getAuthenticationToken()}`
  //       }
  //     });
  //   }
  //   return next.handle(request).pipe(tap(() => {
  //     },
  //     (err: any) => {
  //       if (err instanceof HttpErrorResponse) {
  //         if (err.status !== 401) {
  //           return;
  //         }
  //         this.router.navigate(['auth']);
  //       }
  //     }));
  // }
}
