import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../../../services/user/permission-service';
import {
  EventType,
  IExtendedHarvestEventDto,
} from '../add-event-modal.component';
import { HarvestEvent } from '../../../../../services/api/models/harvest-event';
import { EventFunctionalModes } from '../../events.component';
import {
  DATE_FORMAT,
  EVENT_DATE_FORMAT,
} from '../../../../../utils/string-utils';
import { TranslationService } from '../../../../../services/translations/translation.service';
import { CurrentCropBehaviourService } from '../../../../../services/harvest/current-crop-behaviour.service';
import { UtilsService } from '../../../../../services/utils/utils.service';

@Component({
  selector: 'flush-water-line',
  templateUrl: 'flush-water-line.component.html',
  styleUrls: ['flush-water-line.component.scss'],
})
export class FlushWaterLineComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public event: HarvestEvent;
  @Input() public rowIndex: number;
  @Input() public harvestEvents: IExtendedHarvestEventDto[] = [];
  @Input() public day0Date: Date;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;
  @Input() public maxDate: Date;
  @Input() public startDate: Date;
  @Input() public allEvents: HarvestEvent[];

  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public startDateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public endDateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public recurrentEndBirdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public recurrentIntevalChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public dailyEstimateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onCommentChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public toggleRecurrent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() public dateOutOfRange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public readonly systemNowDate = new Date();
  public isTouched: boolean = false;

  public birdAge: number = 0;
  public maxInterval: number = 1;
  public recurrentEndBirdAge: number = 0;
  public recurrentEndDate: Date;
  public selectedDate: Date;
  public types = [];
  public vaccine = [];
  public EventFunctionalModes = EventFunctionalModes;

  public isDateChanged: boolean = true;
  public isRecurrentEndDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService,
    public utilsService: UtilsService,
    public readonly cropBehaviourService: CurrentCropBehaviourService
  ) {}

  public async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.event ||
      changes.day0Date ||
      changes.maxDate ||
      changes.startDate
    ) {
      if (
        !this.event?.values[this.idByParameterName('Daily Estimate')]?.value
      ) {
        this.event.values.push({
          name: 'Daily Estimate',
          value: '',
        });
      }
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
      console.log('this.event ONCHANGES', this.event);
    }
  }

  public getMaxStartDate() {
    return moment(this.recurrentEndDate).subtract(1, 'days').toDate();
  }

  public getMinStartDate() {
    if (this.functionalMode === EventFunctionalModes.addEvent) {
      const allEventsByCurrentEventType = this.allEvents.filter(
        (event) =>
          event.type === EventType.FlushWaterLine &&
          event.values[this.idByParameterName('startDate')]?.value
      );
      if (allEventsByCurrentEventType.length === 0) {
        if (this.rowIndex === 0) {
          return this.startDate;
        } else if (this.rowIndex !== 0) {
          return moment(
            this.harvestEvents[this.rowIndex - 1].values[
              this.idByParameterName('endDate')
            ].value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        }
      } else if (allEventsByCurrentEventType.length !== 0) {
        if (this.rowIndex === 0) {
          return moment(
            allEventsByCurrentEventType[0]?.values[
              this.idByParameterName(
                'endDate',
                allEventsByCurrentEventType[0].values
              )
            ]?.value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        } else if (this.rowIndex !== 0) {
          return moment(
            this.harvestEvents[this.rowIndex - 1].values[
              this.idByParameterName('endDate')
            ].value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        }
      }
    } else if (this.functionalMode === EventFunctionalModes.editEvent) {
      const allEventsByCurrentEventType = this.allEvents.filter(
        (event) =>
          event.type === EventType.FlushWaterLine &&
          event.values[this.idByParameterName('startDate')]?.value
      );
      const indexOfEditableEvent = allEventsByCurrentEventType.findIndex(
        (event) => event.id === this.event.id
      );
      if (indexOfEditableEvent === allEventsByCurrentEventType.length - 1) {
        return this.startDate;
      } else {
        return moment(
          allEventsByCurrentEventType[indexOfEditableEvent + 1]?.values[
            this.idByParameterName(
              'endDate',
              allEventsByCurrentEventType[indexOfEditableEvent + 1].values
            )
          ]?.value
        )
          .set({
            hour: +moment(this.startDate).format('HH'),
            minute: +moment(this.startDate).format('mm'),
            seconds: +moment(this.startDate).format('ss'),
          })
          .add(1, 'days')
          .toDate();
      }
    }
  }

  public getMinEndDate() {
    return moment(this.selectedDate).add(1, 'days').toDate();
  }

  public getMaxEndDate() {
    return this.harvestEvents[this.rowIndex + 1]?.date
      ? moment(this.harvestEvents[this.rowIndex + 1]?.date).toDate()
      : this.maxDate;
  }

  public setWizardDate() {
    this.startDate = this.day0Date;
    this.selectedDate = moment(this.day0Date)
      .add(this.event.birdAge, 'days')
      .toDate();
    this.birdAge = moment(this.selectedDate).diff(this.startDate, 'days');
    this.changeSelectedDate(this.selectedDate);
  }

  public setEditEventdate() {
    console.log('EVENT TO EDIT: ', this.event);
    this.birdAge = this.event.birdAge;
    this.selectedDate = moment(this.event.date).toDate();
    this.recurrentEndDate = moment(
      this.event.values[this.idByParameterName('endDate')]?.value
    ).toDate();
    this.setMaxInterval();
    this.recurrentEndBirdAge =
      +this.event.values[this.idByParameterName('endBirdAge')]?.value;
  }

  public setAddEventDate() {
    const allEventsByCurrentEventType = this.allEvents.filter(
      (event) =>
        event.type === EventType.FlushWaterLine &&
        event.values[this.idByParameterName('startDate')]?.value
    );
    if (!this.event.values[this.idByParameterName('startDate')]?.value) {
      this.selectedDate = this.maxDate
        ? moment(this.maxDate)
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .toDate()
        : moment(this.systemNowDate)
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .toDate();
    } else {
      if (allEventsByCurrentEventType.length === 0) {
        if (this.rowIndex === 0) {
          this.selectedDate = this.maxDate
            ? moment(this.maxDate)
                .set({
                  hour: +moment(this.startDate).format('HH'),
                  minute: +moment(this.startDate).format('mm'),
                  seconds: +moment(this.startDate).format('ss'),
                })
                .toDate()
            : moment(this.systemNowDate)
                .set({
                  hour: +moment(this.startDate).format('HH'),
                  minute: +moment(this.startDate).format('mm'),
                  seconds: +moment(this.startDate).format('ss'),
                })
                .toDate();
        } else if (this.rowIndex !== 0) {
          this.selectedDate = moment(
            this.harvestEvents[this.rowIndex - 1].values[
              this.idByParameterName('endDate')
            ].value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        }
      } else if (allEventsByCurrentEventType.length !== 0) {
        if (this.rowIndex === 0) {
          this.selectedDate = moment(
            allEventsByCurrentEventType[0].values[
              this.idByParameterName(
                'endDate',
                allEventsByCurrentEventType[0].values
              )
            ].value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        } else if (this.rowIndex !== 0) {
          this.selectedDate = moment(
            this.harvestEvents[this.rowIndex - 1].values[
              this.idByParameterName('endDate')
            ].value
          )
            .set({
              hour: +moment(this.startDate).format('HH'),
              minute: +moment(this.startDate).format('mm'),
              seconds: +moment(this.startDate).format('ss'),
            })
            .add(1, 'days')
            .toDate();
        }
      }
    }
    this.dateChanged.emit(
      moment(this.selectedDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .format(EVENT_DATE_FORMAT)
    );
    this.birdAge = !this.event.birdAge
      ? moment(this.selectedDate).diff(this.startDate, 'days')
      : this.event.birdAge;
    this.event.birdAge = !this.event.birdAge
      ? this.birdAge
      : this.event.birdAge;

    // set recurrent start date
    if (this.event.values[this.idByParameterName('startDate')]?.value) {
      this.event.values[this.idByParameterName('startDate')].value = moment(
        this.selectedDate
      ).format(EVENT_DATE_FORMAT);
    }

    // set recurrent dates
    this.recurrentEndDate = moment(this.selectedDate)
      .set({
        hour: +moment(this.startDate).format('HH'),
        minute: +moment(this.startDate).format('mm'),
        seconds: +moment(this.startDate).format('ss'),
      })
      .add(1, 'days')
      .toDate();
    if (this.event.values[this.idByParameterName('endDate')]?.value) {
      this.event.values[this.idByParameterName('endDate')].value = moment(
        this.recurrentEndDate
      ).format(EVENT_DATE_FORMAT);
    }

    this.recurrentEndBirdAge =
      moment(this.recurrentEndDate).diff(
        this.currentHarvest.startDate,
        'days'
      ) > 80
        ? 80
        : moment(this.recurrentEndDate).diff(
            this.currentHarvest.startDate,
            'days'
          );
    if (this.event.values[this.idByParameterName('startBirdAge')]?.value) {
      this.event.values[this.idByParameterName('startBirdAge')].value = !this
        .event.values[this.idByParameterName('endBirdAge')]?.value
        ? this.recurrentEndBirdAge.toString()
        : this.event.values[this.idByParameterName('startBirdAge')]?.value;
    }
    console.log('EVENT', this.event);
    console.log('recurrentEndBirdAge', this.recurrentEndBirdAge);
    console.log('recurrentEndDate', this.recurrentEndDate);
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate
      ? moment(this.maxDate).diff(this.startDate, 'days')
      : 80;

    if (Number(value) >= this.recurrentEndBirdAge) {
      this.birdAge = this.recurrentEndBirdAge - 1;
    } else if (Number(value) < 0) {
      this.birdAge = 0;
    } else {
      this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
      this.isTouched = false;
    }

    // this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    // this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public changeRecurrentEndBirdAge(value) {
    const maxBirdAge = this.maxDate
      ? moment(this.maxDate).diff(this.startDate, 'days')
      : 80;
    if (Number(value) <= this.birdAge) {
      this.recurrentEndBirdAge = this.birdAge + 1;
    } else {
      this.recurrentEndBirdAge =
        Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    }
    this.isTouched = false;

    this.recurrentEndBirdAgeChanged.emit(this.recurrentEndBirdAge);
  }

  public idByParameterName(name: string, specificArray?) {
    if (specificArray) {
      return specificArray.findIndex((value) => {
        return value.name === name;
      });
    } else {
      return this.event.values.findIndex((value) => {
        return value.name === name;
      });
    }
  }

  public changeSelectedDate(date: Date, isWizard?: boolean) {
    this.isTouched = true;
    this.selectedDate = date;
    this.dateChanged.emit(moment(date).format(EVENT_DATE_FORMAT));
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      // this.birdAge = moment(date).diff(this.currentHarvest.startDate, 'days');
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(this.startDate, 'days');
      if (this.event.values[this.idByParameterName('intervalDays')]?.value) {
        this.setMaxInterval();
        this.event.values[this.idByParameterName('intervalDays')].value =
          +this.event.values[this.idByParameterName('intervalDays')].value >
          this.maxInterval
            ? this.maxInterval.toString()
            : this.event.values[this.idByParameterName('intervalDays')].value;
      }
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public changeRecurrentEndDate(date: Date) {
    this.isTouched = true;
    this.recurrentEndDate = date;
    this.endDateChanged.emit(moment(date).format(EVENT_DATE_FORMAT));

    this.setMaxInterval();
    this.event.values[this.idByParameterName('intervalDays')].value =
      +this.event.values[this.idByParameterName('intervalDays')].value >
      this.maxInterval
        ? this.maxInterval.toString()
        : this.event.values[this.idByParameterName('intervalDays')].value;

    this.recurrentEndBirdAge = moment(date)
      .set({
        hour: +moment(this.startDate).format('HH'),
        minute: +moment(this.startDate).format('mm'),
        seconds: +moment(this.startDate).format('ss'),
      })
      .diff(moment(this.startDate).format(EVENT_DATE_FORMAT), 'days');
  }

  public setMaxInterval() {
    this.maxInterval = moment(
      moment(this.recurrentEndDate).format(DATE_FORMAT)
    ).diff(moment(moment(this.selectedDate).format(DATE_FORMAT)), 'days');
  }

  public isDateOutOfCropRange() {
    if (this.maxDate) {
      this.dateOutOfRange.emit(
        moment(this.recurrentEndDate).isAfter(this.maxDate)
      );
      return moment(this.recurrentEndDate).isAfter(this.maxDate);
    } else {
      this.dateOutOfRange.emit(false);
      return false;
    }
  }

  public commentChanged(value: string) {
    this.onCommentChanged.emit(value);
  }

  public onDailyEstimateChanged(value: string) {
    this.dailyEstimateChanged.emit(value);
  }

  public onIntervalChanged(value: number) {
    this.recurrentIntevalChanged.emit(value);
  }

  public onToggleRecurrent() {
    this.toggleRecurrent.emit();
    this.setAddEventDate();
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }

  protected readonly Number = Number;
}
