<div class="popup">
  <div class="popup__body">
    <div class="popup__body-content">
      <div class="display-flex details width-100">
        <div class="menus__menu-label select">
          {{ "PROFILE.Language.Select" | translate }}
        </div>
      </div>
      <div class="display-flex languages width-100">
        <ng-container  *ngFor="let language of LanguageEnum | keyvalue">
          <div (click)="selectedLanguage = language.value; isChangedLanguage = true" class="menus__menu language" [ngClass]="{'selected': language.value === this.selectedLanguage}">
            {{"PROFILE.Language." + language.value | translate}}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="display-flex end width-100 farms-button-wrapper">
      <button
        [disabled]="this.selectedLanguage === this.profileData.language || !isChangedLanguage"
        class="blue-rounded-button" (click)="updateMyUserDetails()">
        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
      </button>
    </div>
  </div>
</div>
