import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { IProfile } from '../profile-modal.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { MyService } from '../../../../services/api/services/my.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AvatarService } from '../../../../services/reserved-services/avatar.service';
import {UserDetailsService} from '../../../../services/user/user-details.service';

@Component({
  selector: 'profile-details',
  templateUrl: 'profile-details.component.html',
  styleUrls: ['profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnDestroy {
  @Input() public profileData: IProfile;

  public isChangePassword: boolean = false;

  public passwordNew: string = '';
  public passwordConfirm: string = '';

  private destroyed$ = new Subject();

  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  public file: any = null;

  constructor(
    public myService: MyService,
    public userDetailsService: UserDetailsService,
    private router: Router,
    public avatarService: AvatarService
  ) {}

  public updateMyUserDetails() {
    this.myService
      .updateMyUserDetails({
        body: {
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          phone: this.profileData.phone,
          language: this.profileData.language,
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showSaveError.bind(this))
      )
      .subscribe((res) => {
        this.userDetailsService.setUserDetails(res);
      });
  }

  public showSaveError(error: HttpErrorResponse) {
    this.showSaveFail$.next(true);
    return throwError(error);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
