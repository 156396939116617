/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityGroupNotificationSetting } from '../models/facility-group-notification-setting';
import { FacilityGroupNotificationSettingDto } from '../models/facility-group-notification-setting-dto';
import { NotificationTitle } from '../models/notification-title';
import { NotificationType } from '../models/notification-type';

@Injectable({
  providedIn: 'root',
})
export class FacilityGroupNotificationSettingService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getList
   */
  static readonly GetListPath = '/api/facilityGroup/notification/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<FacilityGroupNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.GetListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityGroupNotificationSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: {
  }): Observable<Array<FacilityGroupNotificationSetting>> {

    return this.getList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityGroupNotificationSetting>>) => r.body as Array<FacilityGroupNotificationSetting>)
    );
  }

  /**
   * Path part for operation addSetting
   */
  static readonly AddSettingPath = '/api/facilityGroup/notification/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting$Response(params?: {
    body?: FacilityGroupNotificationSettingDto
  }): Observable<StrictHttpResponse<FacilityGroupNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.AddSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting(params?: {
    body?: FacilityGroupNotificationSettingDto
  }): Observable<FacilityGroupNotificationSetting> {

    return this.addSetting$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupNotificationSetting>) => r.body as FacilityGroupNotificationSetting)
    );
  }

  /**
   * Path part for operation findByFacilityGroupId
   */
  static readonly FindByFacilityGroupIdPath = '/api/facilityGroup/notification/setting/facilityGroupId/{facilityGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByFacilityGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByFacilityGroupId$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<FacilityGroupNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.FindByFacilityGroupIdPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityGroupNotificationSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByFacilityGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByFacilityGroupId(params: {
    facilityGroupId: number;
  }): Observable<Array<FacilityGroupNotificationSetting>> {

    return this.findByFacilityGroupId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityGroupNotificationSetting>>) => r.body as Array<FacilityGroupNotificationSetting>)
    );
  }

  /**
   * Path part for operation getSetting
   */
  static readonly GetSettingPath = '/api/facilityGroup/notification/setting/id/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<FacilityGroupNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.GetSettingPath, 'get');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting(params: {
    settingId: number;
  }): Observable<FacilityGroupNotificationSetting> {

    return this.getSetting$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupNotificationSetting>) => r.body as FacilityGroupNotificationSetting)
    );
  }

  /**
   * Path part for operation getSetting_1
   */
  static readonly GetSetting_1Path = '/api/facilityGroup/notification/setting/title/{title}/type/{type}/facilityGroupId/{facilityGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_1$Response(params: {
    facilityGroupId: number;
    title: NotificationTitle;
    type: NotificationType;
  }): Observable<StrictHttpResponse<FacilityGroupNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.GetSetting_1Path, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.path('title', params.title, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSetting_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_1(params: {
    facilityGroupId: number;
    title: NotificationTitle;
    type: NotificationType;
  }): Observable<FacilityGroupNotificationSetting> {

    return this.getSetting_1$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupNotificationSetting>) => r.body as FacilityGroupNotificationSetting)
    );
  }

  /**
   * Path part for operation updateSetting_2
   */
  static readonly UpdateSetting_2Path = '/api/facilityGroup/notification/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSetting_2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_2$Response(params: {
    settingId: number;
    body?: FacilityGroupNotificationSettingDto
  }): Observable<StrictHttpResponse<FacilityGroupNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.UpdateSetting_2Path, 'put');
    if (params) {
      rb.path('settingId', params.settingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSetting_2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_2(params: {
    settingId: number;
    body?: FacilityGroupNotificationSettingDto
  }): Observable<FacilityGroupNotificationSetting> {

    return this.updateSetting_2$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupNotificationSetting>) => r.body as FacilityGroupNotificationSetting)
    );
  }

  /**
   * Path part for operation deleteSetting
   */
  static readonly DeleteSettingPath = '/api/facilityGroup/notification/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<FacilityGroupNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupNotificationSettingService.DeleteSettingPath, 'delete');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting(params: {
    settingId: number;
  }): Observable<FacilityGroupNotificationSetting> {

    return this.deleteSetting$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupNotificationSetting>) => r.body as FacilityGroupNotificationSetting)
    );
  }

}
