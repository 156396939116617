import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  CustomerHatcheryService,
  HarvestEventsLookupService,
  HarvestMetricTargetService,
  HarvestService,
  KpiFarmSettingsService,
  LookupTableService,
  RegisteredDeviceService,
  VaccinationService,
} from 'src/app/services/api/services';
import {
  Breed,
  FacilityGroupDto,
  HarvestCreateAuxiliaryDataResponse,
  HarvestCreateRequest,
  HarvestCustomerDefaultsDto,
  HarvestEvent,
  HarvestFullResponse,
  HarvestSex,
  HouseType,
  LanguageEnum,
  PermissionName,
  VaccinationDto,
  VaccinationTitle,
} from 'src/app/services/api/models';
import { TranslationService } from '../../../services/translations/translation.service';
import moment from 'moment';
import { DATE_FORMAT, EVENT_DATE_FORMAT } from '../../../utils/string-utils';
import { EventType } from '../events/add-event-modal/add-event-modal.component';
import { PermissionService } from '../../../services/user/permission-service';
import { UtilsService } from '../../../services/utils/utils.service';
import { DropdownListItem } from '../dropdown-list/dropdown-list-model';
import { POUND_TO_KG } from '../../pipes/units-convert.pipe';
import { EventFunctionalModes } from '../events/events.component';
import { EConfirmationModalModes } from '../modals/confirmation-modal/confirmation.component';
import { BroodingService } from '../../../services/brooding/brooding.service';
import * as _ from 'lodash';
import { Predicate, Query } from '@syncfusion/ej2-data';
import {
  EpochStateModel,
  EpochStateModelMinMaxMode,
} from '../time-picker/epoch-state-model';
import { TIME_12_PM } from '../modals/add-thinning-modal/add-thinning-modal.component';
import { CurrentCropBehaviourService } from '../../../services/harvest/current-crop-behaviour.service';

enum CultivationType {
  ChickPlacement = 'ChickPlacement',
  FarmHatching = 'FarmHatching',
}

export const TIME_10_AM = '1970-01-01T10:00:00Z';

export enum BroodingTypes {
  Whole = 'Whole',
  Part = 'Part (Manual)',
  Auto = 'Part (Automatic)',
}

const MAX_DAY_0_DATE: number = 7;
const MAX_OPEN_CROP_DATE: number = 4;

const MIN_AV_WEIGHT = 0.025;
const MAX_AV_WEIGHT = 0.07;

@Component({
  selector: 'start-new-crop',
  templateUrl: 'start-new-crop.component.html',
  styleUrls: ['start-new-crop.component.scss'],
})
export class StartNewCropComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public crop: number;
  @Input() public farm: FacilityGroupDto;
  @Input() public currentHarvest: HarvestFullResponse;
  @Output() public onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onCropConfirm: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('breed') public breed: any;
  @ViewChild('probiotic') public probiotic: any;

  public backUpWeights = { avWeights: [] };
  private translationServiceSubscription: Subscription;

  public MIN_AV_WEIGHT = MIN_AV_WEIGHT;
  public MAX_AV_WEIGHT = MAX_AV_WEIGHT;

  public epochSelectedDate = new EpochStateModel(
    EpochStateModelMinMaxMode.Time,
    Date.parse(TIME_10_AM) / 1000
  );

  public fields = { value: 'value', text: 'text' };

  public previousSelectedLanguage: string;

  public broodingModes: DropdownListItem[] = [
    {
      value: 'NONE',
      disabled: false,
      icon: null,
      displayValue: this.translationService.translate(
        'CROP_CARD.' + BroodingTypes.Whole
      ),
    },
    {
      value: 'MANUAL',
      disabled: false,
      icon: null,
      displayValue: this.translationService.translate(
        'CROP_CARD.' + BroodingTypes.Part
      ),
    },
  ];

  //    {
  //       value: 'AUTO',
  //       disabled: false,
  //       icon: null,
  //       displayValue: this.translationService.translate(
  //         'CROP_CARD.' + BroodingTypes.Auto
  //       ),
  //     },

  public selectedBroodingMode: DropdownListItem = this.broodingModes[0];

  public currentStep: number = 1;
  public maxStep: number = 1;
  public minDate: Date;
  public maxDay0Date: Date;
  public maxEggPlacementDate: Date;
  public auxiliaries: HarvestCreateAuxiliaryDataResponse;
  public isAllowCustomHatchery = true;
  public hatcheryNamesList: any[] = [];
  public brandList: DropdownListItem[] = [];
  public selectedBrand: DropdownListItem;
  public probioticList: DropdownListItem[] = [];
  public selectedProbiotic: DropdownListItem;
  public veryFirstBroodingEvent: HarvestEvent;
  public day0Date: Date;
  public openCropDate: Date;
  public waterFeedRatio: number = 1.7;
  public eggPlacementDate: Date;
  public isConfirmModal: boolean = false;
  public totalNumberOfChicks: number = 0;
  public harvestSex: typeof HarvestSex = HarvestSex;
  public nowDate: Date;
  public selectDate: string;
  public isShowConfirmationBeforeClose: boolean = false;
  public harvestEvents: HarvestEvent[] = [];
  public previousCropEvents: HarvestEvent[] = [];
  public previousCropEventsOriginal: HarvestEvent[] = [];

  public previousVaccineEvents: HarvestEvent[] = [];
  public previousFeedEvents: HarvestEvent[] = [];
  public previousNutraceuticalEvents: HarvestEvent[] = [];
  public previousAllOtherEvents: HarvestEvent[] = [];

  public myFarmCropCardAddBrooding: PermissionName =
    PermissionName.MyFarmCropCardAddBrooding;

  public coccidiostat = [];
  public additives = [];
  public mill = [];
  public ration = [];
  public vaccinationDisease = [];
  public hatcheryTypes = [];
  public hatcheryDiseases = [];
  public hatcheryVaccinesList = [];
  public nutraceuticalDuration = [];
  public nutraceutical = [];
  public reason = [];
  public types = [];
  public vaccine = [];
  public medicationDisease = [];
  public medicationDuration = [];
  public antibiotics = [];
  public selectedOtherEvent: EventType = EventType.AddMedication;
  public eventTypesEnum: typeof EventType = EventType;
  public confirmationPopupTitle: string;
  public confirmarmationPopupText: string;
  public confirmationPopupMode: EConfirmationModalModes =
    EConfirmationModalModes.Confirmation;
  public selectedAdditive: string;
  public selectedCoccidiostat: string;
  public selectedMill: string;
  public selectedRation: string;
  public selectedNutraceuticalDuration: string;
  public selectedNutraceutical: string;
  public selectedReason: string;
  public startDate: Date;
  public maxDate: Date;
  public birdAge: number = 0;
  public selectedCountry: DropdownListItem<string>;
  public countries: DropdownListItem[] = [];
  public vaccination;
  public feed;
  public medication;
  public nutraceuticals;
  public hatcheryVaccination;
  public isShowBeforeCloseModal: boolean = false;
  public isTouched: boolean = false;
  public isDefaultCountry: boolean = false;
  public hasPreviousCrop: boolean = false;
  public previousCropStartDate: string;
  public eventFunctionalMode = EventFunctionalModes.wizard;
  public cultivationTypesEnum: typeof CultivationType = CultivationType;
  public selectedCultivationType: CultivationType =
    CultivationType.ChickPlacement;
  public newCrop = {
    flockType: this.harvestSex.AsHatched as HarvestSex,
    brandId: null,
    cropNumber: null,
    cropNumberAssigned: false,
    deliveryNotes: [],
    hatcheryVaccines: [],
    vaccineId: null,
    probioticId: null,
    integratorId: null,
    inactivatedVaccineId: '',
    comments: '',
    deliveryNote: '',
    maxStockingDensity: 0,
    waterFeedRatio: null,
    openDate: '',
    facilityId: this.customerSelectorsService.currentHouse?.id,
    targetWeightAtClearance: null,
    targetBirdAgeAtClearance: null,
  };

  public defaultTargetBirdAgeAtClearance = null;
  public newCropOriginal;

  public selectedZonesForInitialBrooding: Array<number> = [];
  public selectedDurationForInitialBrooding: number = 16;
  public isAllZonesSelected: boolean = false;
  public customersFavouriteBreedList: Breed[] = [];
  public defaults: HarvestCustomerDefaultsDto;
  private destroyed$ = new Subject();

  public myFarmCropCardViewEvents: PermissionName =
    PermissionName.MyFarmCropCardViewEvents;

  public get acpNumber() {
    const acp = JSON.parse(this.farm?.standards ?? '{}').acp;
    return acp;
  }

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly translationService: TranslationService,
    private harvestService: HarvestService,
    private lookupTableService: LookupTableService,
    public utilsService: UtilsService,
    public readonly permissionService: PermissionService,
    public kpiFarmSettingsService: KpiFarmSettingsService,
    private harvestEventsLookupService: HarvestEventsLookupService,
    public broodingService: BroodingService,
    public harvestMetricTargetService: HarvestMetricTargetService,
    public customerHatcheryService: CustomerHatcheryService,
    public cropBehaviourService: CurrentCropBehaviourService,
    public registeredDeviceService: RegisteredDeviceService
  ) {}

  public ngOnInit() {
    const lastCropDate = this.customerSelectorsService?.lastCrop?.closeDate;
    if (lastCropDate) {
      this.minDate = new Date(
        moment(lastCropDate).add(1, 'days').format(DATE_FORMAT)
      );
    } else {
      this.minDate = new Date(
        moment(this.customerSelectorsService.currentHouse.openDate).format(
          DATE_FORMAT
        )
      );
      if (!!this.openCropDate) {
        this.day0Date = this.openCropDate;
      }
    }
    if (
      moment(this.day0Date).diff(moment(lastCropDate), 'days') === 0 &&
      !!lastCropDate
    ) {
      const nowDateString = moment(this.day0Date)
        .add(1, 'days')
        .format(DATE_FORMAT);
      this.day0Date = new Date(nowDateString);
    }
    this.harvestService
      .getHarvestCreateAuxiliaryDataResponse()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (aux) => {
        this.auxiliaries = aux;
        const hatcheryRes = await this.customerHatcheryService
          .findByCustomerHatcheryId({
            customerId: this.customerSelectorsService.customerId,
          })
          .toPromise();
        this.hatcheryNamesList = aux.hatcheryList
          .filter((hatchery) => {
            const isActiveHatchery = hatcheryRes.find(
              (h) => h.hatchery.name === hatchery.name
            )?.isActive;
            return hatchery.name === 'Default' || isActiveHatchery;
          })
          .map((item) => {
            return {
              value: item.name,
              text:
                item.name === 'Default'
                  ? item.name
                  : item.name +
                    ' - ' +
                    hatcheryRes.find((h) => h.hatchery.name === item.name)
                      .postalCode,
            };
          });
        this.harvestService
          .getDefaults({
            customerId: this.customerSelectorsService.customerId,
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.defaults = res;
            this.waterFeedRatio = this.defaults.waterFeedRatio;
            this.customersFavouriteBreedList = res.breeds;
            aux.brandList.forEach((auxBreed, index) => {
              if (
                res.breeds.find(
                  (customersFavouriteBreed) =>
                    customersFavouriteBreed.brandName.toLowerCase() ===
                    auxBreed.name.toLowerCase()
                )
              ) {
                this.brandList.push({
                  value: auxBreed.id,
                  displayValue: auxBreed.name,
                  icon: null,
                  disabled: false,
                });
              }
            });
            this.lookupTableService
              .getHarvestEventsMap_1()
              .pipe(take(1))
              .subscribe((lookupTableres) => {
                console.log('OLD VACCINE AND HATCHERY', lookupTableres);
                this.leaveOnlyDefaultVaccines(lookupTableres);
              });
          });
        this.probioticList.push({
          value: ' ',
          displayValue: '',
          icon: null,
          disabled: false,
        });
        this.probioticList = aux.probioticList.map((item) => ({
          value: item.id,
          displayValue: item.name,
          icon: null,
          disabled: false,
        }));
      });
    this.setMaxDay0Date();
    this.setMaxEggPlacementDate();

    this.startDate = this.currentHarvest?.startDate
      ? moment(this.currentHarvest?.startDate).toDate()
      : undefined;
    this.maxDate = this.currentHarvest?.closeDate
      ? moment(this.currentHarvest?.closeDate).toDate()
      : undefined;
    this.nowDate = moment().toDate();
    this.birdAge = moment(
      this.currentHarvest?.closeDate ? this.maxDate : this.nowDate
    ).diff(this.startDate, 'days');

    this.selectDate = this.currentHarvest?.closeDate
      ? moment(this.maxDate).format(EVENT_DATE_FORMAT)
      : moment(this.nowDate).format(EVENT_DATE_FORMAT);

    this.previousSelectedLanguage = this.translationService.selectedLanguage;

    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      this.MIN_AV_WEIGHT = parseFloat(
        Number(+this.MIN_AV_WEIGHT / POUND_TO_KG).toFixed(3)
      );
      this.MAX_AV_WEIGHT = parseFloat(
        Number(+this.MAX_AV_WEIGHT / POUND_TO_KG).toFixed(3)
      );
    }

    this.translationServiceSubscription =
      this.translationService.languageChangeSubject.subscribe((value) => {
        if (
          this.translationService.selectedLanguage === LanguageEnum.EnUs &&
          this.previousSelectedLanguage !== LanguageEnum.EnUs
        ) {
          this.previousSelectedLanguage = LanguageEnum.EnUs;
          this.backUpWeights['targetWeightAtClearance'] = Number(
            this.backUpWeights['targetWeightAtClearance'] / POUND_TO_KG
          );
          this.backUpWeights['avWeights'] = this.backUpWeights['avWeights'].map(
            (weight) => parseFloat(Number(+weight / POUND_TO_KG).toFixed(3))
          );
          this.MIN_AV_WEIGHT = parseFloat(
            Number(+this.MIN_AV_WEIGHT / POUND_TO_KG).toFixed(3)
          );
          this.MAX_AV_WEIGHT = parseFloat(
            Number(+this.MAX_AV_WEIGHT / POUND_TO_KG).toFixed(3)
          );
        } else if (this.previousSelectedLanguage === LanguageEnum.EnUs) {
          this.previousSelectedLanguage =
            this.translationService.selectedLanguage;
          this.backUpWeights['targetWeightAtClearance'] = Number(
            this.backUpWeights['targetWeightAtClearance'] * POUND_TO_KG
          );
          this.backUpWeights['avWeights'] = this.backUpWeights['avWeights'].map(
            (weight) => parseFloat(Number(+weight * POUND_TO_KG).toFixed(3))
          );
          this.MIN_AV_WEIGHT = parseFloat(
            Number(+this.MIN_AV_WEIGHT * POUND_TO_KG).toFixed(3)
          );
          this.MAX_AV_WEIGHT = parseFloat(
            Number(+this.MAX_AV_WEIGHT * POUND_TO_KG).toFixed(3)
          );
        } else {
          this.previousSelectedLanguage =
            this.translationService.selectedLanguage;
          this.brandSelected(this.selectedBrand);
        }
      });
  }

  public onFiltering(e: any) {
    console.log('filtering');
    e.preventDefaultAction = true;
    let predicate = new Predicate('text', 'contains', e.text, true);
    predicate = predicate.or('value', 'contains', e.text, true);
    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;
    e.updateData(this.hatcheryNamesList, query);
  }

  public get cropNumber() {
    return this.cropBehaviourService.cropNumber;
  }

  public getSelectedNote(index) {
    if (this.hatcheryNamesList.length) {
      return this.newCrop.deliveryNotes[index]?.hatcheryName;
    } else {
      return 'Default';
    }
  }

  public leaveOnlyDefaultVaccines(lookupTableres) {
    const defaultVaccines = this.defaults.vaccines;
    console.log('DEFAULT VACCINES', defaultVaccines);
    const hatcheryVaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.HatcheryVaccine
    );
    const vaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.VaccineG
    );
    lookupTableres.VACCINE_G = this.convertMapToObject(vaccines);
    lookupTableres.HATCHERY_VACCINE = this.convertMapToObject(hatcheryVaccines);

    this.setDropdownValues(lookupTableres);
  }

  public changeSelectedDateTime(value, i) {
    this.selectDate = value;
    this.previousAllOtherEvents[i].date = this.selectDate;
  }

  public convertMapToObject(vaccines: VaccinationDto[]) {
    let allTypesList: string[] = [
      ...new Set(vaccines.map((item) => item.type)),
    ];

    let vaccinesObj = {};

    vaccines.forEach((vaccine) => {
      vaccinesObj[vaccine.country] = {};

      const typesArrayOfArrays = [];
      allTypesList.forEach((type) => typesArrayOfArrays.push([type, null]));
      vaccinesObj[vaccine.country] = Object.fromEntries(typesArrayOfArrays);

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        if (vaccinesObj[vaccine.country][type] === null) {
          let diseasesArrayOfArrays = [];
          let diseasePerType = [
            ...new Set(
              vaccines.map((vaccine1) => {
                if (vaccine1.type === type) {
                  return vaccine1.disease;
                }
              })
            ),
          ];

          diseasePerType = diseasePerType.filter((el) => {
            return el !== undefined;
          });

          diseasePerType.forEach((disease) =>
            diseasesArrayOfArrays.push([disease, []])
          );
          vaccinesObj[vaccine.country][type] = Object.fromEntries(
            diseasesArrayOfArrays
          );
        }
      });

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        Object.keys(vaccinesObj[vaccine.country][type]).forEach((disease) => {
          let filteredVaccines = vaccines.map((item) => {
            if (item.type === type && item.disease === disease) {
              return item.vaccine;
            }
          });
          filteredVaccines = filteredVaccines.filter((el) => {
            return el !== undefined;
          });
          vaccinesObj[vaccine.country][type][disease] = filteredVaccines;
        });
      });
    });

    console.log('vaccinesObj', vaccinesObj);
    return vaccinesObj;
  }

  public setDropdownValues(res) {
    const feed = res?.FEED;
    const nutraceutical = res?.NUTRACEUTICAL;
    const medication = res?.MEDICATION;
    this.vaccination = res?.VACCINE_G;
    this.feed = res?.FEED;
    this.medication = res?.MEDICATION;
    this.nutraceuticals = res?.NUTRACEUTICAL;
    this.hatcheryVaccination = res?.HATCHERY_VACCINE;

    this.countries = _.uniq([
      ...Object.keys(this.hatcheryVaccination),
      ...Object.keys(this.vaccination),
    ]).map((item) => ({
      value: item,
      disabled: false,
      icon: null,
      displayValue: item,
    }));

    this.findDefaultCountry();
    this.setDefaultLists();

    // @ts-ignore
    this.coccidiostat = feed?.Coccidiostat;
    this.coccidiostat.push('Other');
    this.coccidiostat.unshift('None');
    this.additives = feed?.Additives;
    this.additives.push('Other');
    this.additives.unshift('None');
    this.ration = feed?.Ration;
    this.mill = feed?.Mill;
    this.nutraceuticalDuration = nutraceutical?.Duration;
    this.nutraceutical = nutraceutical?.Nutraceutical;
    this.nutraceutical.push('Other');
    this.reason = nutraceutical?.Reason;
    this.selectedAdditive = this.additives[0];
    this.selectedCoccidiostat = this.coccidiostat[0];
    this.selectedMill = this.mill[0];
    this.selectedRation = this.ration[0];
    this.selectedNutraceuticalDuration = this.nutraceuticalDuration[0];
    this.selectedNutraceutical = this.nutraceutical[0];
    this.selectedReason = this.reason[0];
    this.medicationDisease = medication?.Disease;
    this.medicationDisease.push('Other');
    this.medicationDuration = medication?.Duration;
    this.medicationDuration.push('Other');
    this.antibiotics = medication?.Antibiotics;
    this.antibiotics.push('Other');

    this.findDefaultEvent();
  }

  public setDefaultLists() {
    const vaccination = this.vaccination[this.selectedCountry.value];
    this.types = vaccination ? Object.keys(vaccination) : [];
    this.vaccinationDisease = vaccination
      ? Object.keys(vaccination[this.types[0]])
      : null;
    this.vaccine = vaccination
      ? vaccination[this.types[0]][this.vaccinationDisease[0]]
      : null;
    this.hatcheryTypes = Object.keys(
      this.hatcheryVaccination[this.selectedCountry.value]
    );
    this.hatcheryDiseases = Object.keys(
      this.hatcheryVaccination[this.selectedCountry.value][
        this.hatcheryTypes[0]
      ]
    );
    this.hatcheryVaccinesList =
      this.hatcheryVaccination[this.selectedCountry.value][
        this.hatcheryTypes[0]
      ][this.hatcheryDiseases[0]];
  }

  public findDefaultCountry() {
    const currentFarmCountry =
      this.customerSelectorsService.currentFarm.country;
    const currentFarmCountryIndex = this.countries.findIndex(
      (item) => item.value === currentFarmCountry
    );
    const currentCustomerCountry =
      this.customerSelectorsService.currentCustomer.country;
    const currentCustomerCountryIndex = this.countries.findIndex(
      (item) => item.value === currentCustomerCountry
    );

    if (currentFarmCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentFarmCountryIndex];
      this.countryChanged(this.selectedCountry);
    } else if (currentCustomerCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentCustomerCountryIndex];
      this.countryChanged(this.selectedCountry);
    } else {
      this.isDefaultCountry = true;
      const unitedKingdomIdx = this.countries.findIndex(
        (item) => item.value === 'United Kingdom'
      );
      this.selectedCountry = this.countries[unitedKingdomIdx];
      this.countryChanged(this.selectedCountry);
    }
    console.log('currentFarmCountry', currentFarmCountry);
    console.log('currentCustomerCountry', currentCustomerCountry);
    console.log('selectedCountry', this.selectedCountry);
  }

  public onEpochChanged(epoch) {
    this.epochSelectedDate = epoch;
    const timeString = this.convertEpochToStringTime(epoch);
    if (
      this.selectedCultivationType === this.cultivationTypesEnum.ChickPlacement
    ) {
      let dateString = moment(this.day0Date).format(EVENT_DATE_FORMAT);
      let dateStringWithSelectedTime =
        dateString.split('').slice(0, 11).join('') + timeString;
      this.day0Date = moment(dateStringWithSelectedTime).toDate();
      this.changeOnlyTimeOfDate(epoch, 'openCropDate');
    } else if (
      this.selectedCultivationType === this.cultivationTypesEnum.FarmHatching
    ) {
      let dateChickPlacementString = moment(this.day0Date).format(
        EVENT_DATE_FORMAT
      );
      let dateEggPlacementString = moment(this.eggPlacementDate).format(
        EVENT_DATE_FORMAT
      );
      let dateChickPlacementStringWithSelectedTime =
        dateChickPlacementString.split('').slice(0, 11).join('') + timeString;
      let dateEggPlacementStringStringWithSelectedTime =
        dateEggPlacementString.split('').slice(0, 11).join('') + timeString;
      this.day0Date = moment(dateChickPlacementStringWithSelectedTime).toDate();
      this.eggPlacementDate = moment(
        dateEggPlacementStringStringWithSelectedTime
      ).toDate();
      this.changeOnlyTimeOfDate(epoch, 'openCropDate');
      console.log('eggPlacementDate', this.eggPlacementDate);
    }
  }

  public changeOnlyTimeOfDate(epoch, fieldToChange: string) {
    if (fieldToChange === 'openCropDate') {
      const timeString = this.convertEpochToStringTime(this.epochSelectedDate);
      let dateString = moment(this.openCropDate).format(EVENT_DATE_FORMAT);
      let dateStringWithSelectedTime =
        dateString.split('').slice(0, 11).join('') + timeString;
      this.openCropDate = moment(dateStringWithSelectedTime).toDate();
    }
  }

  public convertEpochToStringTime(epoch) {
    return moment.unix(epoch.epochTime).utc().format('HH:mm');
  }

  public findDefaultEvent() {
    if (this.customerSelectorsService.cropsList.length > 0) {
      console.log('HAS PREVIOUS CROP');
      this.hasPreviousCrop = true;
      this.getLastCropEvents(false);
      this.getLastCropData(false);
    } else {
      console.log('HAS NOT PREVIOUS CROP');
      this.hasPreviousCrop = false;
      this.getLastCropEvents(true);
      this.getLastCropData(true);
    }
  }

  public getLastCropEvents(isDataFromNeighbourHouse: boolean) {
    const indexOfHouseWithCrop =
      this.customerSelectorsService.housesList.lastIndexOf(
        this.customerSelectorsService.housesList.find(
          (house) => house.crops.length > 0
        )
      );
    if (this.permissionService.hasPermission(this.myFarmCropCardViewEvents)) {
      if (
        this.customerSelectorsService.housesList[indexOfHouseWithCrop]?.crops[0]
          ?.cropUniqueId > -1 ||
        this.customerSelectorsService?.cropsList[0]?.id > -1
      ) {
        this.harvestService
          .getEvents({
            harvestId: isDataFromNeighbourHouse
              ? this.customerSelectorsService.housesList[indexOfHouseWithCrop]
                  ?.crops[0].cropUniqueId
              : this.customerSelectorsService.cropsList[0].id,
          })
          .pipe(take(1))
          .subscribe((res) => {
            this.previousCropStartDate = isDataFromNeighbourHouse
              ? this.customerSelectorsService.housesList[indexOfHouseWithCrop]
                  .crops[0].startDate
              : this.customerSelectorsService.cropsList[0].startDate;
            this.previousCropEvents = res;
            this.previousCropEventsOriginal = res;
            console.log('previousCropEvents', this.previousCropEvents);
            this.setPopulatedValues();
          });
      }
    }
  }

  public getLastCropData(isDataFromNeighbourHouse: boolean) {
    const indexOfHouseWithCrop =
      this.customerSelectorsService.housesList.lastIndexOf(
        this.customerSelectorsService.housesList.find(
          (house) => house.crops.length > 0
        )
      );
    if (
      this.customerSelectorsService.housesList[indexOfHouseWithCrop]?.crops[0]
        ?.cropUniqueId > -1 ||
      this.customerSelectorsService?.cropsList[0]?.id > -1
    ) {
      this.harvestService
        .getHarvestFullResponse({
          harvestId: isDataFromNeighbourHouse
            ? this.customerSelectorsService.housesList[indexOfHouseWithCrop]
                .crops[0].cropUniqueId
            : this.customerSelectorsService.cropsList[0].id,
        })
        .pipe(take(1))
        .subscribe(async (res) => {
          console.log('PREV CROP', res);
          //LEAVE IT AS IT IS NOW, DON'T DELETE
          // this.newCrop.deliveryNotes = res?.deliveryNotes.map((item) => ({
          //   harvestCode: item.harvestCode,
          //   hatcheryName: item.hatchery?.name,
          //   chicksValue: item.chicksValue,
          //   parentHarvestAge: item.parentHarvestAge,
          // }));
          this.addFlock();
          this.newCrop.hatcheryVaccines = res?.hatcheryVaccines;
          this.newCrop.flockType = res?.sex;
          this.newCrop.brandId = res.brand?.id;
          this.selectedBrand =
            this.brandList[
              this.brandList.findIndex(
                (item) => item.value === this.newCrop.brandId
              )
            ];
          this.newCrop.vaccineId = res.vaccine?.id;
          this.newCrop.probioticId = res.probiotic?.id;
          this.selectedProbiotic =
            this.probioticList[
              this.probioticList.findIndex(
                (item) => item.value === this.newCrop.probioticId
              )
            ];
          this.newCrop.integratorId = res.integrator?.id;
          // Do not get comments from prev crop
          // this.newCrop.comments = res?.comments;
          this.newCrop.maxStockingDensity = res?.maxStockingDensity;
          this.newCrop.waterFeedRatio = res?.waterFeedRatio;
          this.newCrop.targetWeightAtClearance = res?.targetWeightAtClearance;
          this.newCrop.targetBirdAgeAtClearance = res?.targetBirdAgeAtClearance;
          if (this.selectedBrand) {
            this.kpiFarmSettingsService
              .getHarvestPredictionWeightValues({
                brandId: this.selectedBrand.value,
                harvestSex: this.newCrop.flockType,
              })
              .pipe(takeUntil(this.destroyed$))
              .subscribe((result) => {
                this.newCrop.targetWeightAtClearance = this.newCrop
                  .targetWeightAtClearance
                  ? this.newCrop.targetWeightAtClearance
                  : result.weightClearence;
                this.newCrop.targetBirdAgeAtClearance = this.newCrop
                  .targetBirdAgeAtClearance
                  ? this.newCrop.targetBirdAgeAtClearance
                  : result.ageClearance;
                this.defaultTargetBirdAgeAtClearance = result.ageClearance;
                this.initWeightCalculations();
              });
          }
          this.harvestMetricTargetService
            .listBrandsTargets({
              customerId: this.customerSelectorsService.currentCustomer.id,
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((breeds) => {
              res.groups.forEach((group, groupIndex) => {
                if (
                  breeds.find((breed) => breed.brandName === group.brand.name)
                    ?.customerId !== null
                ) {
                  this.brandSelected(this.selectedBrand);
                }
              });
            });

          if (!res.hatcheryVaccines) {
            this.newCrop.hatcheryVaccines = [];
          } else {
            this.newCrop.hatcheryVaccines = res?.hatcheryVaccines;
          }
          this.newCropOriginal = JSON.parse(JSON.stringify(this.newCrop));
          this.calculateTotalNumberOfChicks();
        });
    }
  }

  public resetToOriginal() {
    if (this.hasPreviousCrop) {
      if (
        !this.newCropOriginal.targetWeightAtClearance ||
        !this.newCropOriginal.targetBirdAgeAtClearance
      ) {
        this.brandSelected(this.selectedBrand);
      }
      this.newCrop = !!this.newCropOriginal
        ? JSON.parse(JSON.stringify(this.newCropOriginal))
        : this.newCrop;
      this.backUpWeights['targetWeightAtClearance'] =
        this.newCropOriginal.targetWeightAtClearance / 1000;
      this.previousCropEvents =
        this.previousCropEventsOriginal?.length > 0
          ? JSON.parse(JSON.stringify(this.previousCropEventsOriginal))
          : [];
      this.newCrop.waterFeedRatio = this.defaults.waterFeedRatio;
      this.brandSelected({
        value: this.newCropOriginal.brandId,
        disabled: false,
        icon: null,
        displayValue: this.brandList.find(
          (item) => item.value === this.newCropOriginal.brandId
        )?.displayValue,
      });
    } else {
      this.newCrop = {
        flockType: this.harvestSex.AsHatched as HarvestSex,
        brandId: null,
        cropNumber: null,
        cropNumberAssigned: false,
        deliveryNotes: [],
        hatcheryVaccines: [],
        vaccineId: null,
        probioticId: null,
        integratorId: null,
        inactivatedVaccineId: '',
        comments: '',
        deliveryNote: '',
        maxStockingDensity: 0,
        waterFeedRatio: this.defaults.waterFeedRatio,
        openDate: '',
        facilityId: this.customerSelectorsService.currentHouse?.id,
        targetWeightAtClearance: null,
        targetBirdAgeAtClearance: null,
      };
      this.previousCropEvents = [];
      this.addHatchery();
      this.addFlock();
      this.selectedBrand = null;
      this.breed.selectItem(null, null);
      this.selectedProbiotic = null;
      this.probiotic.selectItem(null, null);
    }
    this.setPopulatedValues();
    this.currentStep = 1;
    this.maxStep = 1;
    this.calculateTotalNumberOfChicks();
  }

  public removeEvent(i: number, eventType: string) {
    if (eventType === EventType.AddVaccination) {
      this.previousVaccineEvents.splice(i, 1);
    }
    if (eventType === EventType.AddFeed) {
      this.previousFeedEvents.splice(i, 1);
    }
    if (eventType === EventType.AddNutraceutical) {
      this.previousNutraceuticalEvents.splice(i, 1);
    }
    if (
      eventType !== EventType.AddVaccination &&
      eventType !== EventType.AddFeed &&
      eventType !== EventType.AddNutraceutical
    ) {
      this.previousAllOtherEvents.splice(i, 1);
    }
    this.broodingService.setBroodingDurationRemaining(
      this.previousAllOtherEvents.filter(
        (item) => item.type === EventType.Brooding
      )
    );
  }

  public otherEventTypeChanged(value: EventType, index: number) {
    switch (value) {
      case EventType.AddThinning:
        this.previousAllOtherEvents.splice(index, 1, {
          date: this.selectDate,
          type: EventType.AddThinning,
          values: [
            { name: 'totalKg', value: null },
            { name: 'thinnedValue', value: '0' },
          ],
        });
        break;
      case EventType.AddMedication:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.AddMedication,
          birdAge: 0,
          values: [
            { name: 'Disease', value: this.medicationDisease[0] },
            { name: 'Duration', value: this.medicationDuration[0] },
            { name: 'Medication', value: this.antibiotics[0] },
          ],
        });
        break;
      case EventType.FlushWaterLine:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.FlushWaterLine,
          birdAge: 0,
          values: [
            { name: 'Comment', value: '' },
            { name: 'Daily Estimate', value: '' },
          ],
        });
        break;
      case EventType.WaterSanitation:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.WaterSanitation,
          birdAge: 0,
          values: [{ name: 'Comment', value: '' }],
        });
        break;
      case EventType.PlannedHandWeighingOfBirds:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.PlannedHandWeighingOfBirds,
          birdAge: 0,
          values: [
            { name: 'Number Of Birds', value: null },
            { name: 'Average Weight', value: null },
          ],
        });
        break;
      case EventType.PlannedFootpadScoringOfBirds:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.PlannedFootpadScoringOfBirds,
          birdAge: 0,
          values: [
            { name: 'Score 0', value: null },
            { name: 'Score 1', value: null },
            { name: 'Score 2', value: null },
            { name: 'Number Of Zone', value: null },
          ],
        });
        break;
      case EventType.Brooding:
        this.previousAllOtherEvents.splice(index, 1, {
          date: this.getDateToAddBrooding(),
          type: EventType.Brooding,
          birdAge: 0,
          values: [
            {
              name: 'zonesIdx',
              value: '',
            },
            {
              name: 'Duration',
              value: '1',
            },
          ],
        });
        break;
      case EventType.OtherEvents:
        this.previousAllOtherEvents.splice(index, 1, {
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.OtherEvents,
          birdAge: 0,
          values: [{ name: 'Comment', value: '' }],
        });
        break;
    }
    console.log('previousAllOtherEvents', this.previousAllOtherEvents);
  }

  public getDateToAddBrooding() {
    if (
      this.previousAllOtherEvents.filter(
        (item) => item.type === EventType.Brooding
      ).length === 0
    ) {
      return moment(this.day0Date)
        .add(this.selectedDurationForInitialBrooding, 'days')
        .format(EVENT_DATE_FORMAT);
    } else {
      const previousBroodingEvent: HarvestEvent =
        this.previousAllOtherEvents.filter(
          (item) => item.type === EventType.Brooding
        )[
          this.previousAllOtherEvents.filter(
            (item) => item.type === EventType.Brooding
          ).length - 1
        ];
      return moment(previousBroodingEvent.date)
        .add(+previousBroodingEvent.values[1].value, 'days')
        .format(EVENT_DATE_FORMAT);
    }
  }

  public addOtherEvent(eventType: EventType, index?: number) {
    switch (eventType) {
      case EventType.AddMedication:
        this.previousAllOtherEvents.push({
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.AddMedication,
          birdAge: 0,
          values: [
            { name: 'Disease', value: this.medicationDisease[0] },
            { name: 'Duration', value: this.medicationDuration[0] },
            { name: 'Medication', value: this.antibiotics[0] },
          ],
        });
        break;
      case EventType.Brooding:
        this.previousAllOtherEvents.push({
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.Brooding,
          birdAge: 0,
          values: [
            {
              name: 'zonesIdx',
              value: this.selectedZonesForInitialBrooding.join(),
            },
            {
              name: 'Duration',
              value: this.selectedDurationForInitialBrooding.toString(),
            },
          ],
        });
        this.veryFirstBroodingEvent = this.previousAllOtherEvents?.find(
          (item) => item.type === EventType.Brooding
        );
        console.log('previousAllOtherEvents', this.previousAllOtherEvents);
        break;
    }
    this.broodingService.setBroodingDurationRemaining(
      this.previousAllOtherEvents.filter(
        (item) => item.type === EventType.Brooding
      )
    );
  }

  public setSelectedFootpadScoringZones(event, i) {
    this.previousAllOtherEvents[i].values[3].value = event.join();
  }

  public setPopulatedValues() {
    this.previousVaccineEvents = JSON.parse(
      JSON.stringify(
        this.previousCropEvents
          .filter((item) => item.type === EventType.AddVaccination)
          .slice(0, 5)
      )
    );
    this.previousFeedEvents = JSON.parse(
      JSON.stringify(
        this.previousCropEvents
          .filter((item) => item.type === EventType.AddFeed)
          .slice(0, 10)
      )
    );
    this.previousNutraceuticalEvents = JSON.parse(
      JSON.stringify(
        this.previousCropEvents
          .filter((item) => item.type === EventType.AddNutraceutical)
          .slice(0, 10)
      )
    );
    // if (this.customerSelectorsService.cropsList.length < 1) {
    //   this.previousAllOtherEvents = JSON.parse(
    //     JSON.stringify(
    //       this.previousCropEvents.filter(
    //         (item) =>
    //           item.type !== EventType.AddNutraceutical &&
    //           item.type !== EventType.AddFeed &&
    //           item.type !== EventType.AddVaccination &&
    //           item.type !== EventType.PlannedClearingAge &&
    //           item.type !== EventType.Brooding
    //       )
    //     )
    //   );
    // } else {
    this.previousAllOtherEvents = JSON.parse(
      JSON.stringify(
        this.previousCropEvents.filter(
          (item) =>
            item.type !== EventType.AddNutraceutical &&
            item.type !== EventType.AddFeed &&
            item.type !== EventType.AddVaccination &&
            item.type !== EventType.AddThinning &&
            item.type !== EventType.PlannedClearingAge
        )
      )
    );
    // }

    // Remove recurring events from wizard
    this.previousAllOtherEvents = this.previousAllOtherEvents.filter((ev) => {
      return !ev.values.find(
        (item) => item.name === 'isRecurring' && item.value === 'true'
      );
    });

    // Set duration for 1st brooding event according to selectedDurationForInitialBrooding STRICTLY
    if (
      !!this.previousAllOtherEvents?.find(
        (item) => item.type === EventType.Brooding
      )
    ) {
      this.previousAllOtherEvents.find(
        (item) => item.type === EventType.Brooding
      ).values[1].value = this.selectedDurationForInitialBrooding.toString();
    }

    this.veryFirstBroodingEvent = this.previousAllOtherEvents?.find(
      (item) => item.type === EventType.Brooding
    );

    this.removeBroodingEventsWhichOverLimit();

    this.broodingService.setBroodingDurationRemaining(
      this.previousAllOtherEvents.filter(
        (item) => item.type === EventType.Brooding
      )
    );

    if (!!this.veryFirstBroodingEvent) {
      this.selectedBroodingMode = this.broodingModes[1];
      // this.selectedDurationForInitialBrooding =
      //   +this.veryFirstBroodingEvent?.values[1].value;
    } else {
      this.selectedDurationForInitialBrooding = 16;
    }
    this.previousAllOtherEvents = this.previousAllOtherEvents.slice(0, 5);
    this.setSelectedDurationForInitialBrooding(
      this.selectedDurationForInitialBrooding
    );
    console.log('previousAllOtherEvents', this.previousAllOtherEvents);
    console.log('veryFirstBroodingEvent', this.veryFirstBroodingEvent);
  }

  public removeBroodingEventsWhichOverLimit() {
    let durationSum = 0;
    this.previousAllOtherEvents.forEach((ev, index) => {
      if (ev.type === EventType.Brooding) {
        durationSum += +ev.values[1].value;
        if (durationSum > 30) {
          this.previousAllOtherEvents.splice(index, 1);
        }
      }
    });
  }

  public setSelectedDurationForInitialBrooding(value, idx?) {
    if (idx === undefined) {
      if (
        this.previousAllOtherEvents.find(
          (item) => item.type === EventType.Brooding
        )
      ) {
        this.previousAllOtherEvents.find(
          (item) => item.type === EventType.Brooding
        ).values[1].value = String(value);
        this.selectedDurationForInitialBrooding = value;
      }
    } else {
      this.previousAllOtherEvents[idx].values[1].value = value;
    }

    // if (idx !== this.findLastBroodingIndex()) {
    this.broodingService.setBroodingDurationRemaining(
      this.previousAllOtherEvents.filter(
        (item) => item.type === EventType.Brooding
      )
    );
    // }
    console.log('previousAllOtherEvents', this.previousAllOtherEvents);
  }

  public findLastBroodingIndex() {
    let lastBroodingEventIdx = 0;
    this.previousAllOtherEvents.forEach((item, i) => {
      if (item.type === EventType.Brooding && i > lastBroodingEventIdx) {
        lastBroodingEventIdx = i;
      }
    });
    return lastBroodingEventIdx;
  }

  public changeSelectedDate(date: Date, i: number, eventType: string) {
    this.isTouched = true;
    const dateTime = date.getTime();
    const startTime = this.startDate.getTime();
    const endTime = this.maxDate?.getTime();
    if (dateTime < startTime) {
      date = this.startDate;
    } else if (dateTime > endTime) {
      date = this.maxDate;
    }

    this.harvestEvents[i].date = moment(date).format(EVENT_DATE_FORMAT);
    this.harvestEvents[i].birdAge = moment(date).diff(this.startDate, 'days');
  }

  public setMillValue(value: string, i: number) {
    this.selectedMill = value;
    this.previousFeedEvents[i].values[2].value = value;
  }

  public setHatcheryName(value: string, i: number) {
    if (value !== null) {
      this.newCrop.deliveryNotes[i].hatcheryName = value;
    }
  }

  public collectAllEvents() {
    this.previousFeedEvents.forEach((ev) => this.harvestEvents.push(ev));
    this.previousVaccineEvents.forEach((ev) => this.harvestEvents.push(ev));
    this.previousNutraceuticalEvents.forEach((ev) =>
      this.harvestEvents.push(ev)
    );
    this.previousAllOtherEvents.forEach((ev) => this.harvestEvents.push(ev));

    this.harvestEvents.forEach((item) => delete item.birdAge);
    if (this.maxStep === 1) {
      this.harvestEvents.forEach((item) => (item['isSystem'] = true));
    } else {
      this.harvestEvents.forEach((item) => (item['isSystem'] = false));
    }
    this.harvestEvents.forEach((item) => (item.isDone = false));
  }

  public changeBirdAge(value) {
    value < 1
      ? (this.newCrop.targetBirdAgeAtClearance =
          this.defaultTargetBirdAgeAtClearance)
      : (this.newCrop.targetBirdAgeAtClearance = value);
  }

  public setTimeForAllEventsSameAsDay0() {
    this.harvestEvents.forEach((item) => {
      const timeString = this.convertEpochToStringTime(this.epochSelectedDate);
      let dateString = moment(item.date).format(EVENT_DATE_FORMAT);
      let dateStringWithSelectedTime =
        dateString.split('').slice(0, 11).join('') + timeString;
      item.date = moment(dateStringWithSelectedTime).format(EVENT_DATE_FORMAT);
    });
  }

  public createEventForNewCrop(harvestId: number) {
    this.preparePlannedClearingAgeEvent();
    this.collectAllEvents();
    this.setTimeForAllEventsSameAsDay0();

    console.log('ALL harvestEvents', this.harvestEvents);
    if (!this.mill.includes(this.selectedMill)) {
      this.harvestEventsLookupService
        .addMill({ body: { name: this.selectedMill } })
        .pipe(take(1))
        .subscribe();
    }
    this.harvestService
      .addEvent({
        body: {
          harvestEvents: this.harvestEvents,
          harvestIds: [harvestId],
        },
      })
      .pipe(take(1))
      .subscribe(() => {
        this.isShowBeforeCloseModal = false;
      });
  }

  public preparePlannedClearingAgeEvent() {
    const dateCalculatedAccordingToBirdAge = moment(this.day0Date)
      .add(this.newCrop.targetBirdAgeAtClearance, 'days')
      .set('hour', 10)
      .set('minute', 0)
      .format(EVENT_DATE_FORMAT);

    this.harvestEvents.push({
      date: dateCalculatedAccordingToBirdAge,
      isDone: false,
      // @ts-ignore
      isSystem: true,
      type: EventType.PlannedClearingAge,
      values: [],
    });
  }

  public addEvent(eventType: string) {
    if (
      eventType === EventType.AddFeed &&
      this.previousFeedEvents.length < 10
    ) {
      this.previousFeedEvents.push({
        date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
        type: EventType.AddFeed,
        birdAge: 0,
        values: [
          { name: 'Additives', value: this.additives[0] },
          { name: 'Coccidiostat', value: this.coccidiostat[0] },
          { name: 'Mill', value: this.mill[0] },
          { name: 'Ration', value: this.ration[0] },
          { name: 'Kg', value: null },
          { name: 'BatchNumber', value: null },
        ],
      });
    }
    if (
      eventType === EventType.AddNutraceutical &&
      this.previousNutraceuticalEvents.length < 10
    ) {
      this.previousNutraceuticalEvents.push({
        date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
        type: EventType.AddNutraceutical,
        birdAge: 0,
        values: [
          { name: 'Duration', value: this.nutraceuticalDuration[0] },
          { name: 'Nutraceutical', value: this.nutraceutical[0] },
          { name: 'Reason', value: this.reason[0] },
        ],
      });
    }
    if (
      eventType === EventType.AddVaccination &&
      this.previousVaccineEvents.length < 5
    ) {
      if (this.vaccinationDisease && this.types && this.vaccine) {
        this.previousVaccineEvents.push({
          date: moment(this.day0Date).format(EVENT_DATE_FORMAT),
          type: EventType.AddVaccination,
          birdAge: 0,
          values: [
            { name: 'Disease', value: this.vaccinationDisease[0] },
            { name: 'Type', value: this.types[0] },
            { name: 'Vaccine', value: this.vaccine[0] },
          ],
        });
      }
    }
  }

  public addHatchery() {
    this.newCrop.hatcheryVaccines.push({
      disease: this.hatcheryDiseases[0],
      type: this.hatcheryTypes[0],
      vaccine: this.hatcheryVaccinesList[0],
      vaccineCountry: this.selectedCountry,
    });
  }

  public countryChanged(value: DropdownListItem) {
    console.log('countryChanged');
    this.selectedCountry = value;
    this.previousVaccineEvents = [];
    this.newCrop.hatcheryVaccines.length = 0;
    this.setDefaultLists();
    this.addHatchery();
  }

  public setMaxDay0Date() {
    if (
      this.selectedCultivationType === this.cultivationTypesEnum.ChickPlacement
    ) {
      this.maxDay0Date = undefined;
    } else {
      this.maxDay0Date = new Date(
        moment(this.eggPlacementDate)
          .add(MAX_DAY_0_DATE, 'days')
          .format(DATE_FORMAT)
      );
    }
  }

  public chicksSourceChanged(selectedCultivationType) {
    this.selectedCultivationType = selectedCultivationType;
    this.day0Date = null;
    this.openCropDate = null;
    if (selectedCultivationType === this.cultivationTypesEnum.ChickPlacement) {
      this.eggPlacementDate = null;
    }
    this.setMaxDay0Date();
  }

  public setMaxEggPlacementDate() {
    this.maxEggPlacementDate = new Date(
      moment(this.openCropDate)
        .add(MAX_OPEN_CROP_DATE, 'days')
        .format(DATE_FORMAT)
    );
  }

  public ngOnChanges() {
    this.newCrop.cropNumber = this.crop;
  }

  public cancel() {
    if (!!this.newCrop.brandId) {
      this.confirmationPopupTitle = 'CROP_CARD.Unsaved_Data';
      this.confirmarmationPopupText = 'CROP_CARD.want_save';
      this.confirmationPopupMode = EConfirmationModalModes.Confirmation;
      this.isShowConfirmationBeforeClose = true;
    } else {
      this.onCancel.emit();
    }
  }

  public closeWithoutChanges() {
    this.isShowConfirmationBeforeClose = false;
    this.onCancel.emit();
  }

  public addFlock() {
    console.log('addFlock EMPTY CROP');

    this.newCrop.deliveryNotes.push({
      harvestCode: '',
      hatcheryName: null,
      chicksValue: 0,
      parentHarvestAge: null,
      avWeight: null,
      probioticId: null,
    });
  }

  public removeFlock(flock, i) {
    const { deliveryNotes } = this.newCrop;
    const index = deliveryNotes.indexOf(flock);
    if (index > -1) {
      deliveryNotes.splice(index, 1);
    }
    this.backUpWeights['avWeights'] = this.backUpWeights['avWeights'].filter(
      (weight, index) => i !== index
    );
    this.calculateTotalNumberOfChicks();
  }

  public removeHatcheryVaccine(flock) {
    const { hatcheryVaccines } = this.newCrop;
    const index = hatcheryVaccines.indexOf(flock);
    if (index > -1) {
      hatcheryVaccines.splice(index, 1);
    }
  }

  public changeEggPlacementDateAndMaxDay0(date: Date) {
    this.eggPlacementDate = date;
    this.day0Date = date;
    this.setMaxDay0Date();
  }

  public changeOpenCropDateAndMaxEggPlacementDate(date: Date) {
    this.eggPlacementDate = date;
    this.day0Date = date;
    this.calculateOpenCropDate(date);
    if (
      this.previousAllOtherEvents.findIndex(
        (item) => item.type === EventType.Brooding
      ) > -1
    ) {
      this.previousAllOtherEvents[
        this.previousAllOtherEvents.findIndex(
          (item) => item.type === EventType.Brooding
        )
      ].date = moment(date).format(EVENT_DATE_FORMAT);
    }
    this.setMaxEggPlacementDate();
    this.changeEggPlacementDateAndMaxDay0(date);
    this.onEpochChanged(this.epochSelectedDate);
  }

  public changeDay0Date(date: Date) {
    if (
      this.selectedCultivationType === this.cultivationTypesEnum.ChickPlacement
    ) {
      this.day0Date = date;
      this.calculateOpenCropDate(date);
    } else {
      this.day0Date = date;
    }
    if (
      this.previousAllOtherEvents.find(
        (item) => item.type === EventType.Brooding
      )
    ) {
      this.previousAllOtherEvents.find(
        (item) => item.type === EventType.Brooding
      ).date = moment(this.day0Date).format(EVENT_DATE_FORMAT);
    }
    this.onEpochChanged(this.epochSelectedDate);
  }

  public calculateOpenCropDate(date: Date) {
    const closeDateAndSelectedDateDiff =
      moment(date).diff(this.minDate, 'days') + 1;
    console.log(closeDateAndSelectedDateDiff);

    if (closeDateAndSelectedDateDiff >= this.defaults.daysMonitoring) {
      this.openCropDate = moment(date)
        .subtract(this.defaults.daysMonitoring, 'days')
        .toDate();
    } else {
      this.openCropDate = this.day0Date;
    }
    console.log('eggPlacementDate', this.eggPlacementDate);
    console.log('day0date', this.day0Date);
    console.log('openCropDate', this.openCropDate);
  }

  public startNewCrop() {
    this.isConfirmModal = true;
  }

  public continueCreateCrop() {
    this.isConfirmModal = false;
    if (this.previousCropEvents.length > 0 && this.maxStep < 3) {
      this.confirmationPopupTitle = 'CROP_CARD.not_complete_planning';
      this.confirmarmationPopupText = 'CROP_CARD.plan_automatically_created';
      this.confirmationPopupMode = EConfirmationModalModes.GotIt;
      this.isShowConfirmationBeforeClose = true;
    } else if (this.previousCropEvents.length < 1 && this.maxStep < 3) {
      this.confirmationPopupTitle = 'CROP_CARD.not_complete_planning';
      this.confirmarmationPopupText =
        'CROP_CARD.plan_not_automatically_created';
      this.confirmationPopupMode = EConfirmationModalModes.GotIt;
      this.isShowConfirmationBeforeClose = true;
    } else {
      this.confirmNewCrop();
    }
  }

  public saveCropData() {
    if (this.confirmationPopupMode === EConfirmationModalModes.Confirmation) {
      this.isShowConfirmationBeforeClose = false;
    } else if (this.confirmationPopupMode === EConfirmationModalModes.GotIt) {
      this.confirmNewCrop();
    }
  }

  public async confirmNewCrop() {
    const {
      brandId,
      inactivatedVaccineId,
      flockType,
      integratorId,
      maxStockingDensity,
      probioticId,
      vaccineId,
      comments,
      waterFeedRatio,
      targetWeightAtClearance,
      targetBirdAgeAtClearance,
    } = this.newCrop;

    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      this.newCrop.targetWeightAtClearance =
        this.convertKilogramsToPounds('targetWeightAtClearance') * 1000;

      this.newCrop.deliveryNotes.forEach((item, index) => {
        //Check if value null or '', since null or '' shouldn't be 0, it should be null
        if (
          this.backUpWeights['avWeights'][index] === null ||
          this.backUpWeights['avWeights'][index] === ''
        ) {
          item.avWeight = null;
        } else {
          item.avWeight =
            this.backUpWeights['avWeights'][index] * POUND_TO_KG * 1000;
        }
      });
    } else {
      this.newCrop.targetWeightAtClearance =
        this.backUpWeights['targetWeightAtClearance'] * 1000;

      this.newCrop.deliveryNotes.forEach((item, index) => {
        //Check if value null or '', since null or '' shouldn't be 0, it should be null
        if (
          this.backUpWeights['avWeights'][index] === null ||
          this.backUpWeights['avWeights'][index] === ''
        ) {
          item.avWeight = null;
        } else {
          console.log(item.avWeight);
          item.avWeight = this.backUpWeights['avWeights'][index] * 1000;
        }
      });
    }
    //Check if value null or '', since null or '' shouldn't be 0, it should be null
    this.newCrop.deliveryNotes.forEach((item) => {
      if (item.parentHarvestAge === null || item.parentHarvestAge === '') {
        item.parentHarvestAge = null;
      }
    });

    console.log('newCrop.deliveryNotes', this.newCrop.deliveryNotes);
    const deliveryNotes = this.newCrop.deliveryNotes.map((dn) => ({
      chicksValue: this.utilsService.toNormalValue(dn.chicksValue),
      harvestCode: dn.harvestCode,
      hatcheryName: dn.hatcheryName,
      parentHarvestAge: this.utilsService.toNormalValue(dn.parentHarvestAge),
      avWeight: dn.avWeight,
      probioticId: null,
    }));
    const newHatcheryVaccines = this.newCrop.hatcheryVaccines.map((hv) => ({
      disease: hv.disease,
      type: hv.type,
      vaccine: hv.vaccine,
      vaccineCountry: this.selectedCountry.value,
    }));
    const body: HarvestCreateRequest = {
      brandId: brandId ? Number(brandId) : null,
      comments,
      eggPlacementDate:
        this.selectedCultivationType === this.cultivationTypesEnum.FarmHatching
          ? moment(this.eggPlacementDate).format(EVENT_DATE_FORMAT)
          : null,
      facilityId: this.customerSelectorsService.houseId,
      harvestDeliveryNoteRequestList: deliveryNotes.map((note, index) => {
        if (this.selectedProbiotic) {
          note.probioticId = this.selectedProbiotic.value;
        }

        return note;
      }),
      hatcheryVaccines: newHatcheryVaccines,
      inactivatedVaccineId: inactivatedVaccineId
        ? Number(inactivatedVaccineId)
        : null,
      integratorId: integratorId ? Number(integratorId) : null,
      maxStockingDensity: this.utilsService.toNormalValue(maxStockingDensity),
      openDate: moment(this.openCropDate).format(EVENT_DATE_FORMAT),
      sex: flockType,
      startDate: moment(this.day0Date).format(EVENT_DATE_FORMAT),
      vaccineId: vaccineId ? Number(vaccineId) : null,
      waterFeedRatio: this.waterFeedRatio ? Number(this.waterFeedRatio) : null,
      targetWeightAtClearance: this.newCrop?.targetWeightAtClearance
        ? Number(this.newCrop?.targetWeightAtClearance)
        : null,
      targetBirdAgeAtClearance: this.newCrop?.targetBirdAgeAtClearance
        ? Number(this.newCrop?.targetBirdAgeAtClearance)
        : null,
      partBrooding: this.selectedBroodingMode.value,
      co2Limit: this.defaults.co2Limit,
      darkTimeLimit: this.defaults.darkTimeLimit,
      feedWeightRatio: this.defaults.feedWeightRatio,
      kpiCo2TopLimit: this.defaults.kpiCo2TopLimit,
      kpiDarkPeriodBirdAge: this.defaults.kpiDarkPeriodBirdAge,
      kpiDarkPeriodDailyHour: this.defaults.kpiDarkPeriodDailyHour,
      kpiEnable: this.defaults.kpiEnable,
      kpiWaterConsumptionDeviation: this.defaults.kpiWaterConsumptionDeviation,
      kpiWaterConsumptionDeviationBirdAge:
        this.defaults.kpiWaterConsumptionDeviationBirdAge,
      kpiWeightGainDeviation: this.defaults.kpiWeightGainDeviation,
      kpiWeightGainDeviationBirdAge:
        this.defaults.kpiWeightGainDeviationBirdAge,
    };

    try {
      console.log('NEW CROP WILL BE:', body);
      await this.harvestService
        .createHarvest({ body: body })
        .pipe(take(1))
        .subscribe(async (res) => {
          const outsideHouse = this.customerSelectorsService.housesList.find(
            (house) => house.houseType === HouseType.Outside
          );
          if (this.customerSelectorsService.houseId !== outsideHouse.id) {
            this.createEventForNewCrop(res?.harvestId);
          }

          if (outsideHouse.id
            && this.customerSelectorsService.houseId !== outsideHouse.id
            && (outsideHouse.crops?.every(crop => !!crop.closeDate) || outsideHouse.crops?.length === 0)) {
            const devices = await this.registeredDeviceService
              .getDevicePageData({
                customerId: this.customerSelectorsService.customerId,
                facilityGroupId: this.customerSelectorsService.farmId,
              })
              .toPromise();
            if (this.isAssignedSensorsOutside(devices)) {
              const outsideBody: HarvestCreateRequest = {
                brandId: brandId ? Number(brandId) : null,
                comments,
                eggPlacementDate:
                  this.selectedCultivationType ===
                  this.cultivationTypesEnum.FarmHatching
                    ? moment(this.eggPlacementDate).format(EVENT_DATE_FORMAT)
                    : null,
                facilityId: outsideHouse.id,
                harvestDeliveryNoteRequestList: deliveryNotes.map(
                  (note, index) => {
                    if (this.selectedProbiotic) {
                      note.probioticId = this.selectedProbiotic.value;
                      note.chicksValue = 0;
                    }

                  return note;
                }),
                hatcheryVaccines: newHatcheryVaccines,
                inactivatedVaccineId: inactivatedVaccineId
                  ? Number(inactivatedVaccineId)
                  : null,
                integratorId: integratorId ? Number(integratorId) : null,
                maxStockingDensity:
                  this.utilsService.toNormalValue(maxStockingDensity),
                openDate: moment(this.openCropDate).format(EVENT_DATE_FORMAT),
                sex: flockType,
                startDate: moment(this.day0Date).format(EVENT_DATE_FORMAT),
                vaccineId: vaccineId ? Number(vaccineId) : null,
                waterFeedRatio: this.waterFeedRatio
                  ? Number(this.waterFeedRatio)
                  : null,
                targetWeightAtClearance: this.newCrop?.targetWeightAtClearance
                  ? Number(this.newCrop?.targetWeightAtClearance)
                  : null,
                targetBirdAgeAtClearance: this.newCrop?.targetBirdAgeAtClearance
                  ? Number(this.newCrop?.targetBirdAgeAtClearance)
                  : null,
                partBrooding: this.selectedBroodingMode.value,
                co2Limit: this.defaults.co2Limit,
                darkTimeLimit: this.defaults.darkTimeLimit,
                feedWeightRatio: this.defaults.feedWeightRatio,
                kpiCo2TopLimit: this.defaults.kpiCo2TopLimit,
                kpiDarkPeriodBirdAge: this.defaults.kpiDarkPeriodBirdAge,
                kpiDarkPeriodDailyHour: this.defaults.kpiDarkPeriodDailyHour,
                kpiEnable: this.defaults.kpiEnable,
                kpiWaterConsumptionDeviation:
                  this.defaults.kpiWaterConsumptionDeviation,
                kpiWaterConsumptionDeviationBirdAge:
                  this.defaults.kpiWaterConsumptionDeviationBirdAge,
                kpiWeightGainDeviation: this.defaults.kpiWeightGainDeviation,
                kpiWeightGainDeviationBirdAge:
                  this.defaults.kpiWeightGainDeviationBirdAge,
              };

              this.harvestService
                .createHarvest({ body: outsideBody })
                .pipe(take(1))
                .subscribe(() => {});
            }
          }
        });
      this.isConfirmModal = false;
      this.onCropConfirm.emit();
    } catch (error) {
      console.error(error);
    }
  }

  public isAssignedSensorsOutside(res) {
    return res.devices.some(
      (device) =>
        device.location?.houseName === 'Outside House' ||
        device.location?.houseName === 'External Sensor'
    );
  }

  public ngOnDestroy() {
    this.translationServiceSubscription.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public addStep() {
    this.currentStep = this.currentStep + 1;
    if (this.maxStep >= this.currentStep) {
    } else {
      this.maxStep += 1;
    }
  }

  public calculateTotalNumberOfChicks() {
    this.totalNumberOfChicks = 0;
    this.newCrop.deliveryNotes.forEach((item) => {
      this.totalNumberOfChicks += +this.utilsService.toNormalValue(
        item.chicksValue
      );
    });
  }

  public isShowSex(sex: HarvestSex) {
    if (!!this.selectedBrand?.value) {
      return this.customersFavouriteBreedList.find(
        (item) =>
          item.brandName.toLowerCase() ===
            this.selectedBrand?.displayValue.toLowerCase() && item.sex === sex
      );
    } else {
      this.newCrop.targetBirdAgeAtClearance = null;
      return false;
    }
  }

  public setFlockType(type: HarvestSex) {
    this.newCrop.flockType = type;
    if (this.selectedBrand) {
      this.kpiFarmSettingsService
        .getHarvestPredictionWeightValues({
          brandId: this.selectedBrand.value,
          harvestSex: this.newCrop.flockType,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((result) => {
          this.newCrop.targetWeightAtClearance = result.weightClearence;
          this.newCrop.targetBirdAgeAtClearance = result.ageClearance;
          this.defaultTargetBirdAgeAtClearance = result.ageClearance;
          this.initWeightCalculations();
        });
    }
  }

  public async brandSelected(value: DropdownListItem) {
    !!value
      ? (this.newCrop.brandId = value.value)
      : (this.newCrop.brandId = null);
    if (!!value) {
      this.selectedBrand = value;
      this.setFlockType(
        this.customersFavouriteBreedList.find(
          (item) =>
            item.brandName.toLowerCase() ===
            this.selectedBrand.displayValue.toLowerCase()
        ).sex
      );

      await this.kpiFarmSettingsService
        .getHarvestPredictionWeightValues({
          brandId: value.value,
          harvestSex: this.newCrop.flockType,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.newCrop.targetWeightAtClearance = res.weightClearence;
          this.newCrop.targetBirdAgeAtClearance = res.ageClearance;
          this.defaultTargetBirdAgeAtClearance = res.ageClearance;
          this.initWeightCalculations();
        });
    } else {
      this.backUpWeights['targetWeightAtClearance'] = '';
      this.newCrop.targetBirdAgeAtClearance = null;
    }
  }

  public probioticSelected(value: DropdownListItem) {
    this.selectedProbiotic = value;
    !!value
      ? (this.newCrop.probioticId = value.value)
      : (this.newCrop.probioticId = null);
  }

  public initWeightCalculations() {
    this.backUpWeights['targetWeightAtClearance'] =
      this.convertPoundsToKilograms('targetWeightAtClearance') / 1000;
  }

  public convertPoundsToKilograms(fieldName: string) {
    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      return Number(this.newCrop[fieldName] / POUND_TO_KG);
    } else {
      return this.newCrop[fieldName];
    }
  }

  public convertKilogramsToPounds(fieldName: string) {
    if (this.newCrop[fieldName] === this.backUpWeights[fieldName]) {
      return this.newCrop[fieldName];
    } else if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      return Number(this.backUpWeights[fieldName] * POUND_TO_KG);
    } else {
      return this.backUpWeights[fieldName];
    }
  }

  public houseBroodingModeChanged(ev: DropdownListItem) {
    console.log('houseBroodingModeChanged', ev);
    this.selectedBroodingMode = this.broodingModes.find(
      (item) => item.value === ev.value
    );
    if (ev.value === 'NONE' || ev.value === 'AUTO') {
      this.selectedZonesForInitialBrooding = [];
      this.previousAllOtherEvents = this.previousAllOtherEvents.filter(
        (item) => item.type !== EventType.Brooding
      );
    } else if (ev.value === 'MANUAL') {
      this.addOtherEvent(EventType.Brooding);
    }
    console.log('previousAllOtherEvents', this.previousAllOtherEvents);
  }

  public setSelectedBroodingZones(ev: Array<number>, index?: number) {
    this.selectedZonesForInitialBrooding = ev;
    if (
      this.previousAllOtherEvents.find(
        (item) => item.type === EventType.Brooding
      )
    ) {
      this.previousAllOtherEvents.find(
        (item) => item.type === EventType.Brooding
      ).values[0].value = String(ev);
    }

    if (!!ev) {
      this.isAllZonesSelected =
        ev.length ===
        this.customerSelectorsService.currentHouse.zones.filter(
          (zone) => zone.zoneIndex > 0 && zone.zoneIndex < 190
        ).length;
    }

    if (this.isAllZonesSelected) {
      this.previousAllOtherEvents = this.previousAllOtherEvents.filter(
        (item) => item.type !== EventType.Brooding
      );
    }
  }

  public setValueIfLessThanOne() {
    if (this.selectedDurationForInitialBrooding < 1) {
      this.selectedDurationForInitialBrooding = 1;
      this.setSelectedDurationForInitialBrooding(
        this.selectedDurationForInitialBrooding
      );
    } else if (
      this.selectedDurationForInitialBrooding >
      this.broodingService.getMaxBroodingDuration(
        this.selectedDurationForInitialBrooding
      )
    ) {
      this.selectedDurationForInitialBrooding =
        this.broodingService.getMaxBroodingDuration(
          this.selectedDurationForInitialBrooding
        );
      this.setSelectedDurationForInitialBrooding(
        this.selectedDurationForInitialBrooding
      );
    }
  }

  public setValueIfUserPutUnexpected(value, param, index) {
    if (param === 'avWeight') {
      if (value === '') {
        this.backUpWeights['avWeights'][index] = null;
      } else if (value < this.MIN_AV_WEIGHT) {
        this.backUpWeights['avWeights'][index] = this.MIN_AV_WEIGHT;
      }
    } else if (param === 'parentCropAge') {
      if (value === '') {
        this.newCrop.deliveryNotes[index].parentHarvestAge = null;
      } else if (value < 20) {
        this.newCrop.deliveryNotes[index].parentHarvestAge = 20;
      }
    }
  }
}
