import { NgModule } from '@angular/core';
import { ProfileModalComponent } from './profile-modal.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import { EditNotificationSettingsModalModule } from '../modals/edit-notification-settings-modal/edit-notification-settings-modal.module';
import { ProfileDetailsModule } from './profile-details/profile-details.module';
import { LanguageModule } from './language/language.module';
import {ResetPasswordModule} from './reset-password/reset-password.module';

@NgModule({
  declarations: [ProfileModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    EditNotificationSettingsModalModule,
    ProfileDetailsModule,
    LanguageModule,
    ResetPasswordModule,
    NgOptimizedImage,
  ],
  exports: [ProfileModalComponent],
})
export class ProfileModalModule {}
