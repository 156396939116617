<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header">
      <div>
        {{ "SETTINGS.MY_PROFILE" | translate }}
      </div>
      <div class="popup__body-close">
        <span class="svg close-svg-blue" (click)="closeModal()"></span>
      </div>
    </div>
    <div class="body-content">
      <div class="popup__body-content-right information">
        <div class="column photo-container">
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <div class="photo">
                <img class="photo-image" [src]="avatarUrl">
              </div>
              <label for="fileDropRef" class="photo-logo">
              </label>
              <input class="file" type="file" #fileDropRef id="fileDropRef"
                     (change)="fileBrowseHandler($event.target.files[0])"/>
            </div>
          </div>
        </div>
        <div class="column info-container">
          <div class="row__right">
            <div class="row__right-input-wrapper name">
              <span>{{profileData?.firstName}}</span>
              <span> {{profileData?.lastName}}</span>
            </div>
            <div class="row__right-disabled name">{{profileData.role}}</div>
            <div class="row__right-disabled">{{profileData.customer}}</div>
          </div>
        </div>
        <div class="column phone-container">
          <div class="row__right">
            <div class="row__right-input-wrapper name">
              {{profileData?.phone}}
            </div>
          </div>
          <div class="row__right">
            <div class="row__right-input-wrapper email">
              <span>{{profileData?.email}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__body-content-right settings-body">
        <div class="settings-tabs">
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.PersonalDetails)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.PersonalDetails }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.PersonalDetails | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.ResetPassword)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.ResetPassword }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.ResetPassword | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.Language)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.Language }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Language | translate }}
          </div>
          <div [class.none]="!permissionService.hasPermission(notificationSettingUpdate)"
               class="settings-tab"
               (click)="selectTab(ProfileTabs.Notifications)"
               [ngClass]="{ selected: selectedTab === ProfileTabs.Notifications }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Notifications | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.Timezone)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.Timezone }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Timezone | translate }}
          </div>
        </div>
        <div class="settings-content">
          <edit-notification-settings-modal
            *ngIf="selectedTab === ProfileTabs.Notifications && isEditUser"
            [settings]="settings"
            [isProfileSettings]="true"
            [settingsUpdated]="settingsUpdated$"
            (updateSetting)="updateSetting($event)"
            (onEditRole)="onEditRole($event)"
            (onCloseModal)="getUserNotificationSettings()"
          ></edit-notification-settings-modal>
          <profile-details
            *ngIf="selectedTab === ProfileTabs.PersonalDetails && isEditUser"
            [profileData]="profileData"
          ></profile-details>
          <reset-password
            *ngIf="selectedTab === ProfileTabs.ResetPassword && isEditUser"
            [profileData]="profileData"
          ></reset-password>
          <language
            *ngIf="selectedTab === ProfileTabs.Language && isEditUser"
            [profileData]="profileData"
          ></language>
        </div>
      </div>
    </div>

    <!--    <div class="display-flex end width-100 farms-button-wrapper">-->
    <!--      <button-->
    <!--        class="blue-rounded-button"-->
    <!--        (click)="updateMyUserDetails()"-->
    <!--        [disabled]="(-->
    <!--              passwordNew !== passwordConfirm ||-->
    <!--              passwordNew?.length < 5 ||-->
    <!--              passwordConfirm?.length < 5) &&-->
    <!--              isChangePassword ||-->
    <!--              firstName?.length < 1||-->
    <!--              lastName?.length < 1||-->
    <!--              phone?.length < 1">-->
    <!--        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}-->
    <!--      </button>-->
    <!--    </div>-->
<!--    <div class="popup">
  <div class="popup__body">
    <div class="popup__body-header">
      {{'COMMON.PROFILE.Profile_Setting'| translate}}
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="closeModal()"></span>
    </div>
    <div class="popup__body-content">
      <div class="popup__body-content-left">
        <span>{{'COMMON.PROFILE.Change_your_picture'| translate}}</span>
      </div>
      <div class="popup__body-content-right">
        <div class="row">
          <div class="row__left">{{'COMMON.PROFILE.Customer'| translate}}</div>
          <div class="row__right-disabled"> {{profileData?.customer}}</div>
        </div>
        <div class="row">
          <div class="row__left">{{'COMMON.PROFILE.Role'| translate}}</div>
          <div class="row__right-disabled">{{permissionService.currentRole}}</div>
        </div>
        <div class="row">
          <div class="row__left">{{'COMMON.PROFILE.First_Name'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input [value]="profileData?.firstName" (input)="firstName = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row__left">{{'COMMON.PROFILE.Last_Name'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input [value]="profileData?.lastName" (input)="lastName = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row__left">{{'COMMON.PROFILE.Phone'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input [value]="profileData?.phone" (input)="phone = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row border-bottom cursor-pointer" (click)="isChangePassword = !isChangePassword">
          <div class="row__left">
            <span class="row__left-text">{{'COMMON.PROFILE.Change_Password'| translate}}</span>
          </div>
          <div class="row__right">
            <span class="svg expand-more-small" *ngIf="!isChangePassword"></span>
            <span class="svg expand-less-small" *ngIf="isChangePassword"></span>

          </div>
        </div>
        <div class="row" *ngIf="isChangePassword">
          <div class="row__left">{{'COMMON.PROFILE.Current_Password'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input type="password" (input)="passwordCurrent = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isChangePassword">
          <div class="row__left">{{'COMMON.PROFILE.New_Password'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input type="password" (input)="passwordNew = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isChangePassword">
          <div class="row__left">{{'COMMON.PROFILE.Confirm_Password'| translate}}</div>
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <input type="password" (input)="passwordConfirm = $event.target.value">
            </div>
          </div>
        </div>
        <div class="row__left width-100" *ngIf="isChangePassword">
          <small  class="warn ml-minus-200">{{'COMMON.PROFILE.password_hint' | translate }}</small>
        </div>
        <div class="row err" *ngIf="showSaveFail$ | async">
          {{'COMMON.ERRORS.' + errorMessage | translate}}
        </div>
      </div>
    </div>
    <div class="popup__body-buttons">
      <button class="blue-rounded-button" (click)="closeModal()">{{'COMMON.BUTTONS.CANCEL'| translate}}</button>
      <button class="blue-rounded-button"
              type="submit"
              (click)="updateMyUserDetails()"
              [disabled]="(
              passwordNew !== passwordConfirm ||
              passwordNew?.length < 8 ||
              passwordConfirm?.length < 8 ||
              passwordCurrent?.length < 8) &&
              isChangePassword ||
              firstName?.length < 1||
              lastName?.length < 1||
              phone?.length < 1">
        {{'COMMON.BUTTONS.SAVE'| translate}}
      </button>
    </div>
  </div>
</div>-->
  </div>
</div>

