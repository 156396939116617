import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../common/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { EditNotificationSettingsModalComponent } from './edit-notification-settings-modal.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import {FormsModule} from '@angular/forms';
import {TimePickerModule} from '../../time-picker/time-picker.module';
@NgModule({
  declarations: [EditNotificationSettingsModalComponent],
  imports: [
    PipesModule,
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    TimePickerModule
  ],
  exports: [EditNotificationSettingsModalComponent],
})
export class EditNotificationSettingsModalModule {}
