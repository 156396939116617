import { UtilsService } from '../utils/utils.service';
import { HarvestMetricType } from '../api/models/harvest-metric-type';

export const KPA_CONSTANT = 10;

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const DATA_MODEL_ID_POULTRY = 1;
const ALL_DATA_MODEL = [DATA_MODEL_ID_POULTRY];

export const HARVEST_METRIC_TYPE_WEIGHT = 1;
export const HARVEST_METRIC_TYPE_HUMIDITY = 2;
export const HARVEST_METRIC_TYPE_CO2 = 3;
export const HARVEST_METRIC_TYPE_TEMPERATURE = 4;

export interface IKpisInfo {
  type: HarvestMetricType;
  className: string;
  i18name: string;
  structName: string;
  color: string;
  unit?: string;
  harvestMetricId: number;
  dataModels: number[];
  format: (value: number) => string;
  materialIcon?: string;
  liveName?: string;
  unitSmall?: string;
  icon?: string;
  valuePrecision?: number;
}

export interface IKpisInfoExtended extends IKpisInfo {
  change?: string;
  value?: number;
}

export const KPIS_INFO: IKpisInfo[] = [
  {
    type: HarvestMetricType.Weight,
    className: 'weight',
    i18name: 'Av_weight',
    liveName: 'Weight',
    structName: 'Weight',
    color: '#685dff',
    unit: 'Kg',
    unitSmall: 'g',
    harvestMetricId: HARVEST_METRIC_TYPE_WEIGHT,
    dataModels: ALL_DATA_MODEL,
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/av-weight.png',
    valuePrecision: 3,
  },
  {
    type: HarvestMetricType.WeightGain,
    className: 'weight-gain',
    i18name: 'Weight_Gain',
    liveName: 'WeightGain',
    structName: 'WeightGain',
    color: '#685dff',
    unit: 'Kg',
    unitSmall: 'g',
    harvestMetricId: HARVEST_METRIC_TYPE_WEIGHT,
    dataModels: ALL_DATA_MODEL,
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/av-weight.png',
    valuePrecision: 3,
  },
  {
    type: HarvestMetricType.WeightCv,
    className: 'weight-cv',
    i18name: 'Weight_Cv',
    liveName: 'WeightCv',
    structName: 'WeightCv',
    color: '#f69a98',
    unit: '%',
    harvestMetricId: null,
    dataModels: ALL_DATA_MODEL,
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/av-weight.png',
    valuePrecision: 3,
  },
  {
    type: HarvestMetricType.WaterSelection,
    i18name: 'Water_Selection',
    className: 'water-selection',
    structName: 'waterSelection',
    color: '#22c4dc',
    unit: 'L',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/water-consumption.png',
  },
  {
    type: HarvestMetricType.Feed,
    i18name: 'Water_Consumption',
    className: 'water-consumption',
    structName: 'totalWaterIntake',
    color: '#22c4dc',
    unit: 'L',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/water-consumption.png',
  },
  {
    type: HarvestMetricType.WaterTemperature,
    i18name: 'Temperature',
    className: 'temp-blue',
    structName: 'WaterTemp',
    color: '#2387e4',
    unit: '°C',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/temperature.png',
  },
  {
    type: HarvestMetricType.Co2,
    i18name: 'CO2',
    className: 'co2',
    structName: 'CO2Level',
    color: '#0055a5',
    unit: 'ppm',
    harvestMetricId: HARVEST_METRIC_TYPE_CO2,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/co2.png',
  },
  {
    type: HarvestMetricType.Thi,
    i18name: 'THI',
    className: 'thi',
    structName: 'THI',
    color: '#f1aa00',
    unit: '',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/thi.png',
  },
  {
    type: HarvestMetricType.At,
    i18name: 'AT',
    className: 'at',
    structName: 'AT',
    color: '#f11a70',
    unit: '°C',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/at.png',
  },
  {
    type: HarvestMetricType.Temperature,
    i18name: 'Temperature',
    className: 'temperature',
    structName: 'Temp',
    color: '#c95f1f',
    unit: '°C',
    harvestMetricId: HARVEST_METRIC_TYPE_TEMPERATURE,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/temperature.png',
  },
  {
    type: HarvestMetricType.Humidity,
    i18name: 'Humidity',
    className: 'humidity',
    structName: 'Humidity',
    color: '#007947',
    unit: '%',
    harvestMetricId: HARVEST_METRIC_TYPE_HUMIDITY,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/humidity.png',
  },
  {
    type: HarvestMetricType.Lux,
    i18name: 'LUX',
    className: 'lux',
    structName: 'Lux',
    color: '#00b672',
    unit: 'lux',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/lux.png',
  },
  {
    type: HarvestMetricType.HoursDarkLux,
    i18name: 'HOURS_DARK_LUX',
    className: 'hours-dark-lux',
    structName: 'hoursDarklux',
    color: '#781044',
    unit: 'hrs',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/hours-dark-lux.png',
  },
  {
    type: HarvestMetricType.AirPressure,
    i18name: 'Air_Pressure',
    className: 'air-pressure',
    structName: 'AirPressure',
    color: '#730094',
    unit: 'kPA',
    harvestMetricId: null,
    dataModels: ALL_DATA_MODEL,
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/air-pressure.png',
  },
  {
    type: HarvestMetricType.MortalityAnalysis,
    i18name: 'MORTALITY_ANALYSIS',
    className: 'mortality-analysis',
    structName: 'mortalityAnalysis',
    color: '#98aa4b',
    unit: '%',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/predicted-clearence-bird-age.png',
  },
  {
    type: HarvestMetricType.Mortality,
    i18name: 'DAILY_MORTALITY',
    className: 'mortality',
    structName: 'mortality',
    color: '#a8aa1b',
    unit: '%',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/predicted-clearence-bird-age.png',
  },
  {
    type: 'FEED_ESTIMATED' as HarvestMetricType,
    i18name: 'Feed_Estimated',
    className: 'feed-estimated',
    structName: 'totalWaterIntakeEstimated',
    color: '#eba51d',
    unit: 'L',
    harvestMetricId: null,
    dataModels: [DATA_MODEL_ID_POULTRY],
    format: (value) => formatNumber(value),
    icon: 'assets/img/dashboard/water-consumption.png',
  },
];

export const KPIS_INFO_I18NAMES = KPIS_INFO.map((info) => {
  return info.type;
});

export const GetKpiValue = (kpiValue: string) => {
  const res = Number(kpiValue);
  return isNaN(res) ? undefined : res;
};

export const convertValueToImperial = (
  kpi: IKpisInfoExtended,
  filedName?: string
) => {
  const field = filedName ? filedName : 'value';
  switch (kpi.type) {
    case HarvestMetricType.Weight:
    case HarvestMetricType.WeightGain:
      return UtilsService.convertKilogramsToPounds(kpi[field]);
    case HarvestMetricType.Temperature:
    case HarvestMetricType.At:
      return UtilsService.convertCelsiusToFahrenheit(kpi[field]);
    case HarvestMetricType.Feed:
    case HarvestMetricType.WaterSelection:
      return UtilsService.convertLitersToGallons(kpi[field]);
    default:
      return kpi[field];
  }
};
