import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from '../../services/api/services/base.service';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { CustomerSelectorsService } from 'src/app/services/customer-selectors/customer-selectors.service';
import { UserService } from '../../services/api/services/user.service';
import { PermissionService } from '../../services/user/permission-service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { ConfigService } from '../../services/config/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationService } from '../../services/translations/translation.service';
import { LanguageEnum } from '../../services/api/models/language-enum';
import {PASSWORD_REGEX} from '../invite-user/invite-user.component';

declare let branch: any;

class BranchIoData {
  public pin: string;
  public method: string;
  public email: string;
  public language: string;
}

@Component({
  selector: 'create-password',
  templateUrl: 'create-password.component.html',
  styleUrls: ['create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  public passwordNew: string = '';
  public passwordConfirm: string = '';
  public passwordType: string = 'password';

  public errorMessage: string = '';
  public showAuthFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private destroyed$ = new Subject();
  private userToken: string;

  public email: string;
  public pin: string;

  constructor(
    private baseService: BaseService,
    private authService: AuthService,
    private router: Router,
    private customerSelectorService: CustomerSelectorsService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private permissionService: PermissionService,
    private geolocationService: GeolocationService,
    private configService: ConfigService,
    private translationService: TranslationService
  ) {}

  public get getLogo() {
    return this.geolocationService.getLogoPath();
  }

  public ngOnInit() {
    this.permissionService.clearPermissions();
    this.customerSelectorService.resetService();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.userToken = params['finaliseuser'];
      });
    branch.init(
      this.configService.configuration.branchIoKey,
      async (err, data) => {
        let externalLinkUserData: BranchIoData = <BranchIoData>(
          data.data_parsed.$custom_meta_tags
        );
        console.log(externalLinkUserData);
        this.email = externalLinkUserData.email;
        this.pin = externalLinkUserData.pin;
        if (!!externalLinkUserData.language) {
          this.selectLanguageAccordingToUserData(
            externalLinkUserData.language as LanguageEnum
          );
        }
      }
    );
  }

  public get isValidPassword() {
    return this.passwordNew.match(PASSWORD_REGEX);
  }

  public selectLanguageAccordingToUserData(language: LanguageEnum) {
    this.translationService.selectLanguage(language, true);
  }

  public updateUserPasswordByToken() {
    this.userService
      .forgotPasswordVerify({
        body: {
          email: this.email,
          verificationCode: this.pin,
          newPassword: this.passwordConfirm,
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showErrorMessage.bind(this))
      )
      .subscribe(() => {
        this.router.navigate(['/auth']);
      });
  }

  public showErrorMessage(error: HttpErrorResponse) {
    this.errorMessage = JSON.parse(error.error).Message;
    this.showAuthFail$.next(true);
    return throwError(error);
  }

  public showHidePass(): void {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
